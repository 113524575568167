$(document).ready(function() {
  var langRegex = /([&?]lang=)([^:/?#[\]@!$&'()*+,;=]*)(&|$)/;

  function appendLangToLoiUrls(langRegex) {
    var queryString = window.location.search;
    var matchString = queryString.match(langRegex);
    if (queryString && matchString) {  
      // Links to LOI Registration always contain "/apply/" or "/lois/new"
      $("a[href*='/apply/'], a[href*='/lois/new']").each(function() {
        var href = $(this).attr('href');
        var separator = /\?/.test(href) ? '&' : '?';
        var new_href = href + separator + 'lang=' + matchString[2];
        $(this).attr('href', new_href);
      });
    }
  }

  $('#language-selector').change(function() {
    // Add lang query string parameter. If exists, replace.
    // We use a regex here, because URLSearchParams isn't available for IE
    var newLang = $(this).val();
    var currentUrl = window.location.href;
    var langRegex = /([&?]lang=)([^:/?#[\]@!$&'()*+,;=]*)(&|$)/;

    // If query string parameter for lang already exists ...
    if (langRegex.test(currentUrl)) {
      window.location = currentUrl.replace(langRegex, '$1' + newLang + '$3');
    } else {
      var separator = /\?/.test(currentUrl) ? '&' : '?';
      window.location = currentUrl + separator + 'lang=' + newLang;
    }
  });

  // call this on every page load
  appendLangToLoiUrls(langRegex);
});
