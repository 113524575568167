(function($){
    // Gets length in characters, rather than UTF-16 code units (which is
    // used by default for JavaScript string length). This allows a
    // multibyte character to be counted as a single character.
    function getCharacterLength(str) {
        return Array.from(str).length;
    }

    // This is taken mostly from the redactor 'counter' plugin -
    // We strip the rich text of html tags before counting characters.
    function clean(html) {
        html = html.replace(/\t/gi, '');
        html = html.replace(/\n/gi, '');
        html = html.replace(/\r/gi, '');
        html = html.replace(/\u200B/g, '');
        html = html.replace(/&nbsp;/g, '1');
        html = html.replace(/&amp;/g, '1');
        html = html.replace(/&lt;/g, '1');
        html = html.replace(/&gt;/g, '1');
        html = html.replace(/<\/p>/gi, '');
        html = html.replace(/<\/(.*?)>/gi, '');
        html = html.replace(/<(.*?)>/gi, '');
        html = html.trim();
        return html;
    }

    $.fn.extend({
        // Used to get the character count of input within text areas and rich text editors
        getCharacterCount: function (obj) {
            if (obj.code === undefined) {
                var text = $(obj).val();
            } else {
                var $t = $('<div>' + obj.code.get() + '</div>');
                var text = $t.html();
            }
            text = clean(text);
            return getCharacterLength(text);
        }
    })
})(jQuery);
