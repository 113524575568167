(function($){

  var INVALID_ATTR = "inputMaskIsInvalid";

  function InputMask($el) {
    this.$el        = $el;
    this.$inputWrap = this.$el.closest('.input');
    this.mask       = this.$el.data("input-mask");
    this.helpText   = this.$el.data("input-mask-help-text");
    this.options    = this.$el.data("input-mask-options");
    this.showingError = false;
    this.length     = this.$el.val().length;
    if (this.helpText) this.addHelpText();
    this.addOptions();
    this.init();
  }

  $.extend(InputMask.prototype, {
    ERROR_TIMEOUT: 3000,
    init: function() {
      if (this.$el.val()) {
        // already something there. don't mask unless user edits.
        this.$el.one('input', this.addInputMask.bind(this));
      } else {
        this.addInputMask();
      }
    },
    addHelpText: function() {
      this.$inlineErr = $("<p>").addClass("inline-errors").addClass("input-mask-error").text(this.helpText);
      this.$inputWrap.append(this.$inlineErr.hide());
    },
    // override currency input mask if local currency.
    // we can't have enough information to watch for currency format
    // changes on the form itself.
    addLocalCurrencyOptions: function() {
      this.options["prefix"] = "";

      var stencil = this.$el.fluxxCardArea().data('stencil');
      if (!stencil) return;

      var currencyFormat = stencil.data.model ? stencil.data.model.currency_style : null;
      if (currencyFormat) this.options["radixPoint"] = currencyFormat.decimalSymbol;
    },
    addOptions: function() {
      var isLocalCurrency = this.$inputWrap.hasClass('amount-local');
      if (isLocalCurrency) this.addLocalCurrencyOptions();

      // handle in/valid chars
      this.options["onKeyValidation"] = this.handleKeyValidation.bind(this);
    },
    hideError: function() {
      if (!this.showingError) return;

      this.$inputWrap.removeClass("error");
      if (this.helpText) this.$inlineErr.hide();
      this.showingError = false;
    },
    showError: function(isSticky) {
      if (this.showingError) return;

      this.$inputWrap.addClass("error");
      if (this.helpText) this.$inlineErr.show();
      this.showingError = true;
      if (!isSticky) setTimeout(this.hideError.bind(this), this.ERROR_TIMEOUT);
    },
    handleValidChar: function() {
      this.hideError();
      this.$el.removeData(INVALID_ATTR);
    },
    handleInvalidChar: function() {
      this.showError();
      this.$el.data(INVALID_ATTR, true);
    },
    handleKeyValidation: function(key, result) {
      if (!result) {
        this.handleInvalidChar();
      } else {
        this.handleValidChar();
      }
    },
    handleBlur: function() {
      var val = this.$el.val();
      var isValid = this.$el[0].inputmask.isValid(val);
      if (isValid || !val.length) {
        this.handleValidChar();
      } else {
        this.showError(true);
        this.$el.data(INVALID_ATTR, true);
      }
    },
    addInputMask: function() {
      // ensure we only have one handler working at a time
      if (this.$el[0].inputmask) {
        this.$el.inputmask('remove');
      }

      this.$el.inputmask(this.mask, this.options);
      this.$el.off('blur').on('blur', this.handleBlur.bind(this));
    }
  });

  function findInvalidInputs($el) {
    return $el.find('.input-mask').filter(function() {
      return $(this).data(INVALID_ATTR);
    });
  }

  $.fn.extend({
    fluxxInputMask: function() {
      return new InputMask(this);
    },
    invalidInputs: function() {
      return findInvalidInputs($(this));
    }
  });
})(jQuery);
