/**
 * jquery.plupload.queue.js
 *
 * Copyright 2009, Moxiecode Systems AB
 * Released under GPL License.
 *
 * License: http://www.plupload.com/license
 * Contributing: http://www.plupload.com/contributing
 */

// JSLint defined globals
/*global plupload:false, jQuery:false, alert:false */

(function($) {
  var uploaders = {};

  var i18n_scope = 'js.uploader.';

  function translate(key) {
    return I18n.t(i18n_scope + key);
  }

  function translate_with_interpolation(key, data) {
    return I18n.t(i18n_scope + key, data);
  }

  function get_display_percentage(file) {
    // if a file has been entirely sent to the database but is waiting on the server response, halt the percentage at 99%
    return (file.status !== plupload.DONE && file.percent == 100) ? 99 : file.percent;
  }

  function renderUI(id, target, settings) {
    // Remove all existing non plupload items
    target.contents().each(function(i, node) {
      node = $(node);

      if (!node.is('.plupload')) {
        node.remove();
      }
    });

    var uploadPanel =
      '<div id="upload-panel" class="plupload_wrapper plupload_scroll">' +
        '<div id="' + id + '_container" class="plupload_container">' +
          '<div class="plupload">' +
            '<div class="plupload_header">' +
              '<div class="plupload_header_content">' +
                '<h2 class="plupload_header_title">' + translate('upload_files') + '</h2>' +
                '<h3 class="plupload_header_text">' + translate('select_or_drag_files') + '</h3>' +
              '</div>' +
            '</div>' +
            '<div class="plupload_content">' +
              '<div class="plupload_table">' +
                '<table class="plupload_filelist plupload_main_table">' +
                  '<thead class="plupload_filelist_header">' +
                    '<th colspan="2" id="document_file_name" class="plupload_file_name">' + _.escape(translate('filename')) + '</th>' +
                    '<th id="document_file_size" class="plupload_file_size">' + translate('size') + '</th>' +
                    '<th id="document_upload_status" class="plupload_file_status">' + translate('status') + '</th>' +
                    '<th  class="plupload_file_action_spacer"></th>' +
                    '<th class="plupload_clearer">&nbsp;</th>' +
                    '<th class="sub-type-wrapper">&nbsp;</th>' +
                  '</thead>' +
                  '<tbody id="' + id + '_filelist" class="plupload_filelist plupload_filelist_tbody"></tbody>' +
                  '<tfoot class="plupload_filelist_footer">' +
                    '<tr class="plupload_filelist_footer_container">' +
                      '<td colspan=2 class="plupload_file_name">' +
                        '<div class="plupload_buttons">' +
                          '<button href="#" class="plupload_button plupload_add">' + translate('add_files') + '</button>' +
                          '<button href="#" class="plupload_button plupload_start" aria-disabled="true">' + translate('start_upload') + '</button>' +
                        '</div>' +
                        '<span class="plupload_upload_status" role="status"></span>' +
                        '<div class="plupload_filelist_progress_bar"></div>' +
                      '</td>' +
                      '<td class="plupload_file_size"><span class="plupload_total_file_size"></span></td>' +
                      '<td class="plupload_file_status"><span class="plupload_total_status"></span></td>' +
                      '<td class="plupload_file_action_spacer">&nbsp;</td>' +
                      '<td class="plupload_clearer">&nbsp;</td>' +
                      '<td>&nbsp;</td>' +
                    '</tr>' +
                  '</tfoot>' +
                '</table>' +
              '</div>' +
            '</div>' +
          '</div>' +
        '</div>' +
        '<input type="hidden" id="' + id + '_count" name="' + id + '_count" value="0" />' +
      '</div>';


    if ($.fluxx.config.google_docs_enabled) {
      var html = '<div class="horizontal-tabs" name="uploader"><ul><li><a href="#upload-panel">' + translate('upload') + '</a></li>';
      html += '<li><a href="/google_docs?'+ settings.url.split('?')[1] +'&reset=true">' + translate('google_docs') + '</a></li>';
      html += '</ul>';

      target.prepend(html + uploadPanel + '</div>');
      // TODO: Possibly combine with version inside of fluxx.card.js
      $('.horizontal-tabs', target).each(function() {
          var $elem = $(this);
          var cookieName = "fluxx_tabs_" + $elem.attr('name');
          var tab_cookie_id = parseInt($.cookie(cookieName)) || 0;
          $('ul:first li', $elem).each(function() {
            var $li = $(this);
            if (!$li.find('a span')[0]) {
              // Provide a span tag inside tabs so that a loading indicator can be displayed
              $li.find('a').html('<span>'+ $li.html() + '</span>')
            }
          });
          $elem.tabs({
            spinner: translate('loading'),
            selected: tab_cookie_id,
            select: function(e,ui) {
              $.cookie(cookieName, ui.index);
            }
          });

          // We want to keep the outer <li> elements non-tabbable, but the jQuery UI code keeps updating the tabindex.
          // We work around that by setting a mutation observer to set tabindex to -1 every time it deviates from that.
          var BrowserMutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
          var observer = new BrowserMutationObserver(function(mutationsList) {
            $.each(mutationsList, function(i, mutation) {
              if (mutation.type == 'attributes' && mutation.attributeName == 'tabindex' && mutation.target.getAttribute('tabindex') == '0') {
                $(mutation.target).attr('tabindex', '-1');
              }
            });
          });
          var config = {
              attributes: true
          };
          $('ul.ui-tabs-nav li', $elem).each(function (i, target) {
            observer.observe(target, config);
          });
        });
    } else {
      target.prepend(uploadPanel);
    }
  }

  $.fn.pluploadQueue = function(settings) {
    if (settings) {
      var preCreateUrl = settings.directToS3 ? settings.url : null;

      this.each(function() {
        var uploader, target, id, showDocumentType, documentTypeOptions, documentTypeSelect;

        showDocumentType = (settings.documentTypeParam && settings.documentTypeUrl);
        var currentDocumentTypeID = settings.modelDocumentTypeID
        if (showDocumentType) {
          var select = '';
          $.ajax({
            url: settings.documentTypeUrl,
            dataType: 'json',
            success: function(data, status, xhr){
              if (!$.isPlainObject(data) || !$.isArray(data['records']) || data['records'].length < 1) {
                showDocumentType = false;
                return false;
              }
              var first = true;
              $(data['records']).each(function() {
                var dataMap = this;
                var selected = currentDocumentTypeID ? currentDocumentTypeID === dataMap.id : first;
                select += '<option data-dropdown-sub-type="' + dataMap.dropdown_sub_type + '" data-subtype="' + dataMap.sub_type_config + '" data-display-custom-text="' + dataMap.display_custom_text + '" data-subtype-dropdown="' + dataMap.config_subtype_dropdown + '" value="' + dataMap.id + '" ' + (selected ? 'selected ="' + selected + '"' : '') + '>' + dataMap.name.replace('<','&lt;').replace('>','&gt;') + '</option>'
                first = false;
              });
              documentTypeSelect = '<select aria-label="dropdown menu" id="doc_type_select" name="plupload_file_type">' + select + '</select>';
              $('#plupload_file_type', target).html('').append(documentTypeSelect);
           }
          });

        }
        target = $(this);
        id = target.attr('id');

        if (!id) {
          id = plupload.guid();
          target.attr('id', id);
        }

        uploader = new plupload.Uploader($.extend({
          dragdrop : true,
          container : id
        }, settings));

        // Call preinit function
        if (settings.preinit) {
          settings.preinit(uploader);
        }

        uploaders[id] = uploader;

        function handleStatus(file) {
          var actionClass;

          if (file.status == plupload.DONE) {
            actionClass = 'plupload_done';
          }

          if (file.status == plupload.FAILED) {
            actionClass = 'plupload_failed';
          }

          if (file.status == plupload.QUEUED) {
            actionClass = 'plupload_delete';
          }

          if (file.status == plupload.UPLOADING) {
            actionClass = 'plupload_uploading';
          }

          $('#' + file.id).attr('class', actionClass).find('a').css('display', 'inline-block');
          if (actionClass == "plupload_done" || actionClass == "plupload_failed") {
            var action = $('#' + file.id + ' .plupload_file_action');
            action.find('a').remove();
            if (actionClass == "plupload_done" && !action.find('.plupload_checkmark').length) {
              action.append($('<span class="plupload_checkmark" alt="Check" aria-label="upload successful"></span>').css('display', 'inline-block'));
            }
            if (actionClass == "plupload_failed" && !action.find('.plupload_errormark').length) {
              action.append($('<span class="plupload_errormark" alt="Error" aria-label="upload failed"></span>').css('display', 'inline-block'));
            }
          }
        }

        function setUploadStatus(text) {
          uploader.stop();
          $('span.plupload_upload_status', target).text(translate(text));
          $('div.plupload_filelist_progress_bar', target).fadeOut(500);
        }

        function updateTotalProgress() {
          var progress = uploader.total.uploaded / uploader.files.length * 100.;
          var progress_int_str = isNaN(progress) ? '0' : parseInt(progress).toString();
          $('span.plupload_total_status', target).html(progress_int_str + '%');
          // total width is 150% when it should be done so that it overflows into other td in row
          $('div.plupload_filelist_progress_bar', target).css('width', 1.5 * progress + '%');

          $('span.plupload_upload_status', target).text(translate_with_interpolation('uploaded_files', {files_uploaded: uploader.total.uploaded, total_files: uploader.files.length}));
          // All files are uploaded
          if (uploader.total.uploaded == uploader.files.length) {
            setUploadStatus('upload_completed')
            // Display "toastr" message on successful upload
            // Note: prevent completed "toastr" from being loaded multiple times
            if (uploader.total.uploaded > 0 && typeof toastr != 'undefined' && $(".toast-message").length == 0) {
              toastr.info(I18n.t('js.uploader.upload_completed'));
            }
          } else if(uploader.total.uploaded + uploader.total.failed == uploader.files.length) {
            setUploadStatus('upload_failed');
          }
        }

        function updateList() {

          var fileList = $('tbody.plupload_filelist', target), inputCount = 0, inputHTML;
          if (uploader.files.length > 0) {
            fileList.find('.plupload_droptext').remove();
          }
          $.each(uploader.files, function(i, file) {
            if (fileList.find('tr#' + uploader.files[i].id).length > 0) {
              return;
            }
            inputHTML = '';

            if ($.fluxx.config.user_is_translator && $.fluxx.config.dashboard_type == 'portal_v1' && settings.translatedDocFilename) {
              file.name = settings.translatedDocFilename + '.' + file.name.split(".")[1]
            }

            if (file.status == plupload.DONE) {
              if (file.target_name) {
                inputHTML += '<input type="hidden" name="' + id + '_' + inputCount + '_tmpname" value="' + plupload.xmlEncode(file.target_name) + '" />';
              }

              inputHTML += '<input type="hidden" name="' + id + '_' + inputCount + '_name" value="' + plupload.xmlEncode(file.name) + '" />';
              inputHTML += '<input type="hidden" name="' + id + '_' + inputCount + '_status" value="' + (file.status == plupload.DONE ? 'done' : 'failed') + '" />';

              inputCount++;

              $('#' + id + '_count').val(inputCount);
            }
            fileList.find('.blankspace_row').remove();
            fileList.append(
              '<tr id="' + file.id + '" aria-live="' + _.escape(file.name) + '" aria-role="listitem">' +
                '<td aria-label="Document file name" aria-labelledby="document_file_name" class="plupload_file_name"><span>' + _.escape(file.name) + '</span></td>' +
                (showDocumentType ?
                '<td aria-label="Document file type" class="plupload_file_type">' + documentTypeSelect + '</td>' : '') +
                '<td aria-role="listitem" aria-label="Document file size" aria-labelledby="document_file_size" class="plupload_file_size">' + plupload.formatSize(file.size) + '</td>' +
                '<td aria-role="listitem" aria-label="Document upload status" aria-labelledby="document_upload_status"  class="plupload_file_status">' + get_display_percentage(file) + '%</td>' +
                '<td aria-role="listitem" class="plupload_file_action"><a href="#" aria-label="Remove file"></a></td>' +
                '<td colspan=2 class="plupload_clearer">&nbsp;</td>' +
                inputHTML +
              '</tr>'
            );
            addBlankRows(fileList)
            var $docTypeSelect = $('td[id=' + file.id + '] div.plupload_file_type select', target);
            if (settings.modelDocumentTypeID && !file.type) {
              $docTypeSelect.val(settings.modelDocumentTypeID);
              file.type = settings.modelDocumentTypeID;
            }
            $docTypeSelect.val(file.type).attr('disabled', (file.percent == 100));


            handleStatus(file);

            $('#' + file.id + '.plupload_delete a').click(function(e) {
              $('#' + file.id).remove();
              fileList.find('.blankspace_row').remove();
              addBlankRows(fileList);
              uploader.removeFile(file);

              e.preventDefault();
            });
          });

          if (settings && ('multi_selection' in settings) && !settings.multi_selection) {
            // Disable add file button
            var $addFileBtn = $('button.plupload_add', target);
            if (uploader.files.length == 1) {
              $addFileBtn.addClass('disabled');
              $addFileBtn.attr('aria-disabled', 'true')
              $addFileBtn.css('pointer-events', 'none');
            } else {
              $addFileBtn.removeClass('disabled');
              $addFileBtn.removeAttr('aria-disabled')
              $addFileBtn.css('pointer-events', 'auto');
            }
          }

          if (settings.modelDocumentTypeID) $('#doc_type_select', target).prop('disabled', true);
          $('#doc_type_select', target).off('change').on('change', function() {
            var $select = $(this);
            var selectedOption = $select.find('option:selected');
            var id = $select.parent().parent().attr('id');
            $.each(uploader.files, function(i, file) {
              if (file.id == id) {
                file.type = $select.val();
                var subTypeYearVal = file.subTypeYear;
                file.subTypeYear = '';
                var subTypeDateVal = file.subTypeDate;
                file.subTypeDate = '';
                var subTypeCustomVal = file.subTypeCustom;
                file.subTypeCustom = '';
                var subTypeSelectVal = file.subTypeSelect;
                file.subTypeSelect = '';

                // Clear out any subtypes that exist
                $select.parent().parent().find('#sub_doc_type').remove();
                var $subTypesWrapper = $('<div class="sub-type-wrapper"></div>');
                $select.parent().append($subTypesWrapper);

                if (selectedOption.data('dropdown-sub-type')) {
                  var documentTypeUrl = settings.documentTypeUrl,
                      queryParams = "";
                  // Make a call to load up the list of sub document types for this doc type if any
                  var querySeparator = settings.documentSubTypeUrl.includes('?') ? '&' : '?';
                  queryParams = querySeparator + 'model_document_type_id=' + selectedOption.val();
                  if (/documentable_id=/.test(documentTypeUrl) && /model_type=/.test(documentTypeUrl)) {
                    var documentableId = documentTypeUrl.match(/documentable_id=([^&]*)/)[1];
                    var modelType = documentTypeUrl.match(/model_type=([^&]*)/)[1];
                    queryParams += '&documentable_id=' + documentableId + '&model_type=' + modelType;
                  }
                  $.ajax({
                    url: settings.documentSubTypeUrl + queryParams,
                    dataType: 'json',
                    success: function(data, status, xhr){
                      if (!$.isPlainObject(data) || !$.isArray(data['records']) || data['records'].length < 1) {
                        return false;
                      }
                      var first = true;
                      if (subTypeSelectVal) first = false;
                      var subSelect = '';
                      $(data['records']).each(function() {
                        var dataMap = this;
                        var selected = (subTypeSelectVal === dataMap.id.toString());
                        subSelect += '<option value="' + dataMap.id + '" ' + (selected ? 'selected = "true"' : '') + '>' + dataMap.value.replace('<','&lt;').replace('>','&gt;') + '</option>';
                        first = false;
                      });
                      var custom = $('<div id="sub_doc_type" class="plupload_file_dropdown_subtype"><select name="plupload_file_dropdown_subtype_selector">' + subSelect + '</select></div>');

                      if ($subTypesWrapper.find('.plupload_file_subtype').length) {
                        //insert  dropdown sub type after date/year sub type
                        $subTypesWrapper.find('.plupload_file_subtype').first().after(custom);
                      } else {
                        $subTypesWrapper.prepend(custom);
                      }
                      custom.find(':input').change(function() {
                        file.subTypeSelect = $(this).val();
                      }).change();
                    }
                  });
                }

                if(selectedOption.data('display-custom-text') == true) {
                  var custom = $('<div id="sub_doc_type" class="plupload_file_subtype_custom doc-custom-text"><label for="custom_text" style="font-weight: bold;">&nbsp;&nbsp;' + translate('custom_text') + '<input id="custom_text" name="subtype_custom" class="doc-subtype-custom" maxlength="255" type="text" value="' + (subTypeCustomVal || '') + '"></label></div>');
                  $subTypesWrapper.prepend(custom);
                  custom.find(':input').change(function() {
                    file.subTypeCustom = $(this).val();
                  }).change();
                }

                if(selectedOption.data('subtype')) {
                  // Handle the 3 different cases of subtype
                  switch(selectedOption.data('subtype')) {
                    case 'date':
                      var dateTime = $('<div id="sub_doc_type" class="plupload_file_subtype date"><label>&nbsp&nbsp' + translate('date') + '<input as="datetime" name="subtype" type="text" placeholder="mm/dd/yyyy" value="' + (subTypeDateVal || '') + '"></label></div>');
                      if ($select.parents('.plupload_done').length) dateTime.find(':input').attr('disabled', true);
                      $subTypesWrapper.prepend(dateTime);
                      dateTime.find(':input').fluxxDatePicker().change(function() {
                        file.subTypeDate = $(this).val()
                      }).change();
                        break;
                    case 'year':
                      var currentYear = Number(subTypeYearVal) || (new Date).getFullYear();
                      var highYear = currentYear + 50;
                      var lowYear = currentYear - 50;
                      var yearOptions = "";
                      for ( var curYear = highYear; curYear > lowYear; curYear-- ) {
                        yearOptions += '<option value="' + curYear + '" ' + (curYear == currentYear ? 'selected = "true"' : '') + '>' + curYear + '</option>'
                      }

                      var yearSelect = $('<div id="sub_doc_type" class="plupload_file_subtype"><label>&nbsp&nbsp' + translate('year') + '<select name="plupload_file_subtype_selector">' + yearOptions + '</select></label>');
                      $subTypesWrapper.prepend(yearSelect);
                      yearSelect.find(':input').change(function() {
                        file.subTypeYear = $(this).val();
                      }).change();
                      break;
                  }
                }

                return false;
              }
            });
          }).change();


          $('span.plupload_total_file_size', target).html(plupload.formatSize(uploader.total.size));

          var $startUploadBtn = $('button.plupload_start', target);
          if (uploader.files.length === 0) {
            $startUploadBtn.attr('aria-disabled', 'true');
            $startUploadBtn.addClass('plupload_disabled');
          } else {
            $startUploadBtn.attr('aria-disabled', 'false');
            $startUploadBtn.removeClass('plupload_disabled');
          }

          // Scroll to end of file list
          fileList[0].scrollTop = fileList[0].scrollHeight;

          updateTotalProgress();

          // Re-add drag message if there is no files
          if (!uploader.files.length && uploader.features.dragdrop && uploader.settings.dragdrop) {
            $('#' + id + '_filelist').append('<td colspan="7" class="plupload_droptext">' + translate('drag_files_here') + '</td>');
          }
        }

        function addBlankRows(fileList)  {
          var blankRow = '<tr class="blankspace_row"><td colspan="7" style="border: none;"></td></tr>';
          var spaces = 3 - fileList.find('tr').length;
          if (spaces > 0) {
            for (var ispace = 0; ispace < spaces; ispace++) {
              fileList.append(blankRow);
            }
          }
        }

        uploader.bind("UploadFile", function(up, file) {
          $('#' + file.id).addClass('plupload_current_file');
        });

        var originalURL = preCreateUrl || uploader.settings.url;
        uploader.bind("BeforeUpload", function(up, file) {
          var adjustedUrl = originalURL +
            ((showDocumentType && file.subTypeYear) ? "&model_document[doc_type_year]=" + file.subTypeYear : "") +
            ((showDocumentType && file.subTypeCustom) ? "&model_document[doc_type_custom]=" + file.subTypeCustom : "") +
            ((showDocumentType && file.subTypeDate) ? "&model_document[doc_type_date]=" + file.subTypeDate : "") +
            ((showDocumentType && file.subTypeSelect) ? "&model_document[model_document_sub_type_id]=" + file.subTypeSelect : "") +
            ((up.settings.filters && up.settings.filters[0] && up.settings.filters[0].uid) ? "&uid=" +  up.settings.filters[0].uid : "");

          // Add the model document type if it doesn't already exist on the originalURL.
          var decodedUrl = decodeURI(originalURL);
          var toBeFound = up.settings.documentTypeParam + "=" + file.type;
          if (showDocumentType && file.type &&  decodedUrl.indexOf(toBeFound) == -1) {
            adjustedUrl += ("&" + up.settings.documentTypeParam + "=" + file.type);
          }

          if (!preCreateUrl) {
            up.settings.url = adjustedUrl;
          } else {
            $.fn.upload_to_s3(adjustedUrl, file, up);
          }
        });


        uploader.bind('Init', function(up, res) {
          renderUI(id, target, settings);


          // Enable rename support
          if (!settings.unique_names && settings.rename) {
            $('#' + id + '_filelist div.plupload_file_name span', target).live('click', function(e) {
              var targetSpan = $(e.target), file, parts, name, ext = "";

              // Get file name and split out name and extension
              file = up.getFile(targetSpan.parents('td')[0].id);
              name = file.name;
              parts = /^(.+)(\.[^.]+)$/.exec(name);
              if (parts) {
                name = parts[1];
                ext = parts[2];
              }

              // Display input element
              targetSpan.hide().after('<input type="text" />');
              targetSpan.next().val(name).focus().blur(function() {
                targetSpan.show().next().remove();
              }).keydown(function(e) {
                var targetInput = $(this);

                if (e.keyCode == 13) {
                  e.preventDefault();

                  // Rename file and glue extension back on
                  file.name = targetInput.val() + ext;
                  targetSpan.text(file.name);
                  targetInput.blur();
                }
              });
            });
          }

          $('button.plupload_add', target).attr('id', id + '_browse');

          up.settings.browse_button = id + '_browse';

          // Enable drag/drop
          if (up.features.dragdrop && up.settings.dragdrop) {
            up.settings.drop_element = id + '_filelist';
            $('#' + id + '_filelist').append('<td colspan=7 class="plupload_droptext">' + translate('drag_files_here') + '</td>');
          }

          $('#' + id + '_container').attr('data-dev-note', 'Using runtime: ' + res.runtime);

          $('button.plupload_start', target).click(function(e) {
            if (!$(this).hasClass('plupload_disabled')) {
              $('.plupload_filelist select, .plupload_filelist input', target).attr('disabled', true);
              uploader.start();
            }

            e.preventDefault();
          });

          $('a.plupload_stop', target).click(function(e) {
            uploader.stop();
            e.preventDefault();
          });

          $('button.plupload_start', target).addClass('plupload_disabled');
          $('button.plupload_start', target).attr('aria-disabled', 'true');
        });


        try {
          uploader.init();
        } catch(exception) {
          alert(translate('error_initializing'));
          return false;
        }


        uploader.bind("Error", function(up, err) {
          var file = err.file, message;

          if (file) {
            message = err.message;

            if (err.details) {
              message += " (" + err.details + ")";
            }

            if (err.code == plupload.FILE_SIZE_ERROR) {
              message = translate('error') + " " + translate('file_too_large') + " " + plupload.formatSize(file.size) + "\n" + translate('smaller_file_request') + " " + plupload.formatSize(up.settings.max_file_size);
              alert(message);
            }

            if (err.code == plupload.FILE_EXTENSION_ERROR) {
              var message = translate('error') + " " + translate('invalid_extension') + " " + _.escape(file.name);
              var file_ext_filter = _.find(up.settings.filters, function (elem) {return elem.extensions !== undefined});
              if (file_ext_filter) {
                message = translate('error') + " " + translate('invalid_extension') + " " + _.escape(file.name) + "\n" + translate('different_type_request') + " " + file_ext_filter.extensions.replace(/,/g,', ');
              }
              alert(message);
            }

            // For files infected with viruses
            if (err.status == 403) {
              response = event.currentTarget.response;

              message = response;
              alert(translate('error') + " " + message);
            }

            if (err.forceAlert) {
              alert(translate('error') + " " + message);
            }

            $('#' + file.id).attr('class', 'plupload_failed').find('a').css('display', 'inline-block').attr('title', message);
          }
        });

        uploader.bind('StateChanged', function() {
          if (uploader.state === plupload.STARTED) {
            $('td.plupload_delete a,div.plupload_buttons', target).hide();
            $('span.plupload_upload_status,div.plupload_progress,a.plupload_stop', target).css('display', 'inline-block');
            $('span.plupload_upload_status', target).text(translate_with_interpolation('uploaded_files', {files_uploaded: 0, total_files: uploader.files.length}));
          } else {
            $('a.plupload_stop,div.plupload_progress', target).hide();
            $('a.plupload_delete', target).css('display', 'inline-block');
          }
        });

        uploader.bind('QueueChanged', updateList);

        uploader.bind('StateChanged', function(up) {
          if (up.state == plupload.STOPPED) {
            updateList();
          }
        });

        uploader.bind('FileUploaded', function(up, file, result) {
          handleStatus(file);
          if (!up.settings.directToS3) return;

          // this is the only way we can check if the upload succeeded
          // s3 put reqs return a 204 upon success
          if (result.status == 204) {
            var url = settings.url.split("?")[0]+"/"+file.modelId+"/finalize";
            var payload = {
              model_document: {
                document_file_size: file.size
              }
            };
            if (up.settings.filters && up.settings.filters[0] && up.settings.filters[0].uid) {
              payload.uid = up.settings.filters[0].uid;
            }

            if ($.fluxx.config.user_is_translator) {
              payload.native_model_document_master_id = up.settings.nativeModelDocumentMasterId
            }

            $.ajax({
              url: url,
              type: 'PUT',
              async: (up.files.length == 1),
              data: payload
            });
          } else {
            var message = translate('upload_fail_1') + "\n" + translate('upload_fail_2');
            up.trigger("Error", {
              file: file,
              message: message,
              forceAlert: true
            });
          }
        });

        uploader.bind("UploadProgress", function(up, file) {
          // Set file specific progress
          $('#' + file.id + ' td.plupload_file_status', target).html(get_display_percentage(file) + '%');

          handleStatus(file);
          updateTotalProgress();
        });

        // Call setup function
        if (settings.setup) {
          settings.setup(uploader);
        }
      });

      return this;
    } else {
      // Get uploader instance for specified element
      return uploaders[$(this[0]).attr('id')];
    }
  };

  $.fn.upload_to_s3 = function(adjustedUrl, file, up) {
    if (file.size === 0) {
      up.trigger("Error", {
        file: file,
        message: 'This document failed to upload. The file appears to be blank. Please try again',
        forceAlert: true
      })
    } else {
      var urlParts = adjustedUrl.split("?");
      var url = urlParts[0] + "/pre_create?" + urlParts[1];

      $.ajax({
        url: url,
        type: 'POST',
        data: { name: file.name },
        async: false,
        success: function(data, status, xhr) {
          file.modelId = data.id;
          up.settings = $.extend({}, up.settings, {
            url: ("//"+$.fluxx.config.direct_to_s3_hostname+"/"+data.s3_config.bucket),
            multipart: true,
            multipart_params: {
              utf8: true,
              acl: data.s3_config.acl,
              key: data.s3_config.path_prefix + "/" + data.document_file_name,
              'X-Amz-Credential': data.s3_config.x_amz_credential,
              'X-Amz-Algorithm': data.s3_config.x_amz_algorithm,
              'X-Amz-Date': data.s3_config.x_amz_date,
              'Policy': data.s3_config.policy,
              'X-Amz-Signature': data.s3_config.x_amz_signature
            }
          });
          if (data.s3_config.hasOwnProperty('security_token')) {
            // IAM role-based auth: add the security token header
            up.settings.multipart_params = $.extend({}, up.settings.multipart_params, {
              "x-amz-security-token": data.s3_config.security_token
            })
          }
        },
        error: function(xhr) {
          up.trigger("Error", {
            file: file,
            message: xhr.responseText,
            forceAlert: true
          })
        }
      });
    }
  }
})(jQuery);
