(function($){
  $.fn.extend({
    selectTransfer: function(options, callback) {
      var defaults = {
        className: 'select-transfer',
        callback: $.noop
      };
      var options = $.extend(defaults, options, {callback: callback});
      return this.each(function(){
        var $original = $(this);
        if ($original.parents('.multiple-select-transfer').data('select-transfer-init')) return;
        $original.parents('.multiple-select-transfer').data('select-transfer-init', true);
        $original.parent().find(':input:hidden').not($original).remove();

        if ($original.callPlugin && $original.callPlugin('selectTransfer')) return this;
        $original.addClass('include-blank');
        if ($original.data('select-transfer'))
          return;
        $original.data('select-transfer', true);
        if ($original.parent().hasClass('checkboxes')) {
          $original.checkBoxes(options, callback);
        } else {
          var $container = $([
            '<div class="', options.className, '">',
              '<select class="unselected" multiple="multiple"></select>',
              '<div class="controls">',
                '<input type="button" value="&gt;" class="select" />',
                '<div class="break"></div>',
                '<input type="button" value="&lt;" class="unselect"/>',
              '</div>',
              '<select class="selected" multiple="multiple"></select>',
            '</div>'
          ].join('')).css({
            height: 100,
            display: $original.css('display')
          });
          var $unselected = $('.unselected', $container).keydown(function(e){
            if (e.which == 39) {
              $('.select', $container).click();
            }
          });
          var $selected = $('.selected', $container).keydown(function(e){
            if (e.which == 37) {
              $('.unselect', $container).click();
            }
          });

          var $controls = $('.controls', $container);
          var $copy = $original.clone();
          $copy.find(':selected').appendTo($selected);
          $copy.children().appendTo($unselected);

          $('select', $container).css({
            width: ('40%'),
            height: '100%'
          });
          $controls.css({
            width: ('20%'),
            display: 'inline-block',
            verticalAlign: 'top',
            textAlign: 'center'
          });
          $('input', $controls).css({
            width: ('40%'),
            margin: 'auto'
          });
          $('.break', $controls).css({
            height: 24
          });

          var updateOriginal = function() {
            var sel = [];
            $selected.children().each(function () {
              sel.push($(this).val());
            });
            $original.val(sel).change();
          };

          var $select = $('.select', $container).click(function() {
            $unselected.find(':selected').remove().appendTo($selected);
            updateOriginal();
          });
          var $unselect = $('.unselect', $container).click(function() {
            $selected.find(':selected').remove().appendTo($unselected);
            updateOriginal();
          });

          $unselected.dblclick(function(e) {$select.click();});
          $selected.dblclick(function(e) {$unselect.click();});

          $original.hide();

          $original.bind('options_updated', function () {
            var items = [];
            $selected.children().each(function() {items.push($(this).val());});
            $unselected.children().remove();
            $original.children().clone().appendTo($unselected);
            $selected.children().remove();
            $unselected.children().each(function() {
              if (items.indexOf($(this).val()) >= 0)
                  $(this).appendTo($selected)
            });
            updateOriginal();
          });

          $container.insertAfter($original);
        }
      });
    },
    checkBoxes: function(options, callback) {
      return this.each(function(){
        var $original = $(this),
            container = '<div class="scroll_checkboxes">';

        if ($original.hasClass('no-reorder')) {
          $original.find('option').each(function() {
            var $option = $(this);
            
            container += '<label><input name="' + $original.attr('name') + '" type="hidden" value=""><input type="checkbox" name="' + $original.attr('name') + '" checked="' + ($option.is(':selected') ? 'checked' : '') +'"} value="' + $option.val() + '">' + $option.html() + '</label>';
          });
        } else {
          $original.find(':selected').each(function() {
            var $option = $(this);
            container += '<label><input name="' + $original.attr('name') + '" type="hidden" value=""><input type="checkbox" name="' + $original.attr('name') + '" checked="checked" value="' + $option.val() + '">' + $option.html() + '</label>';
          }).end().
          find(':not(:selected):not(:disabled)').each(function() {
            var $option = $(this);
            if ($option.html()) container += '<label><input name="' + $original.attr('name') + '" type="hidden" value=""><input type="checkbox" name="' + $original.attr('name') + '" value="' + $option.val() + '">' + $option.html() + '</label>';
          });
        }
        container = $(container + '</div>')
        container.insertAfter($original);
        $("input[type='checkbox']", container).click(function(e) {
          var sel = [];
          $("input[type='checkbox']:checked", container).each(function() {
            sel.push($(this).val());
          })
          $original.val(sel).change();
        });
        $original.hide();
        $original.addClass("select-transfer-original");
        //disable the $original select, so that it won't be inlucded in form submission
        $original.attr('disabled', 'disabled');
      });
    }
  });
})(jQuery);
