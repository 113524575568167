
(function($){
  $.ajaxPrefilter(function(_options, _originalOptions, jqXHR) {
    jqXHR.setRequestHeader('X-Request-Id', $.fluxx.generateUUID());
    if ($.fluxx.config.production == 'false') {
      jqXHR.setRequestHeader('X-Request-Start', Date.now() / 1000);
    }
  });

  _.templateSettings = {
    start       : '{{',
    end         : '}}',
    interpolate : /\{\{(.+?)\}\}/g,
    evaluate    : /\{\%(.+?)\%\}/g,
    escape    : /\{\{\=(.+?)\}\}/g
  };

  var oldHTML = $.html;
  $.html = function(data) {
    $.fluxx.log(data);
  }

  _.mixin({
    addUp: function (set, property) {
      var args = _.toArray(arguments).slice(2);
      return _.reduce($(set),function(m,i){
        return m + $(i)[property].apply($(i), args);
      }, 0);
    },
    callAll: function () {
      var functions = _.toArray(arguments);
      return function() {
        var this_ = this;
        var args  = arguments;
        _.each(functions, function(f){
          try {
            f.apply(this_, args);
          } catch(e) {
            console.trace();
            $.fluxx.log("**Error", e.stack);
          }
        });
      }
    },
    intersectProperties: function (one, two, hierarchies) {
      if (_.isEqual(one, two)) return one;
      var intersect = {};
      _.each(one, function (val, key) {
        if (!$.isArray(val))
          val = [val];
        _.each(val, function(single) {
          _.each($.isArray(two[key]) ? two[key] : [two[key]], function(twoVal) {
            if (hierarchies.indexOf(key) != -1) {
              var i = 0;
              twoVal = twoVal.split('-');
              _.each(single.split('-'), function(id) {
                if (!id)
                  twoVal[i] = '';
                i++;
              });
              twoVal = twoVal.join('-');
            }
            if (single == twoVal) intersect[key] = single;
          });
        });
      });
      return intersect;
    },
    objectWithoutEmpty: function (object, without) {
      if (typeof without == 'undefined')
        without = [];
      if ($.isArray(object)) {
        var filled = [];
        _.each(object, function(item) {
          if (item && (item['name'] == 'q[q]' || ((typeof item.value == 'number') || !_.isEmpty(item.value))) && without.indexOf(item['name']) == -1)
            filled.push(item);
        });
      } else if ($.isPlainObject(object)) {
        var filled = {};
        _.each(_.keys(object), function(key) {
          if (key == 'q' || !_.isEmpty(object[key])) {
            filled[key] = object[key];
          }
        });
      } else
        return object;
      return filled;
    },
    arrayToObject: function (list, filter) {
      var object = {};
      /* Cheap deep clone. */
      _.each(list, function(entry) {
        var entry = filter ? filter(_.clone(entry)) : _.clone(entry);
        if (object[entry.name]) {
          if (!$.isArray(object[entry.name]))
            object[entry.name] =[ object[entry.name] ]
					if (object[entry.name].indexOf(entry.value) == -1)
          	object[entry.name].push(entry.value);
        } else {
          object[entry.name] = entry.value;
        }
      });
      return object;
    },
    isFilterMatch: function (filter, test) {
      if(test == null) {
        return true;
      }

      var keys = _.intersection(_.keys(filter), _.keys(test));
      var fork_state;
      var pass_filter = false;
      var hierarchies = (filter.hierarchies ? filter.hierarchies : []);
      _.each([filter, test], function(obj) {
        _.each(_.keys(obj), function(key) {
          // Hard coding "fork_states" filter item because this behaves as an OR condition, not an AND
          if (key == 'fork_states') {
            if (fork_state && obj[key] && fork_state == obj[key]) {
              pass_filter = true;
            } else {
              fork_state = obj[key]
            }

          }

          if (! _.detect(keys, function(i){return _.isEqual(key,i);})) {
            delete obj[key];
          }
        });
      });
      _.each(_.keys(filter), function(key) {
        if (_.isEmpty(filter[key])) {
          delete filter[key];
          delete test[key];
        };
      });
      $.fluxx.log('--- Cleanded Filter and Test ---', filter, test);
      var result = _.isEqual(
        (_.compose(_.size, _.intersectProperties))(filter, test, hierarchies),
        (_.compose(_.size, _.values))(filter)
      );
      $.fluxx.log('--- isFilterMatch ---');
      return result || pass_filter;
    },
    uniqueNumber: function() {
      if ( typeof _.uniqueNumber.counter == 'undefined' ) {
        _.uniqueNumber.counter = 0;
      }
      return _.uniqueNumber.counter++;
    },
    isValidEmailAddress: function(emailAddress) {
      var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
      return pattern.test(emailAddress);
    }
  });

  $.extend(true, {
    my: {
      cards: $()
    },
    fluxx: {
      config: {
        cards: $('.card'),
        realtime_updates: {
          enabled: false,
          options: {
            url: null
          }
        }
      },
      cache: {},
      realtime_updates: null,
      util: {
        postGSMessage: function(options) {
          window.parent.postMessage(options, $.fluxx.config.gs.url);
        },
        options_with_callback: function(defaults, options, callback) {
          if ($.isFunction(options)) {
            options = {callback: options};
          } else if ($.isPlainObject(options) && $.isFunction(callback)) {
            options.callback = callback;
          }
          return $.extend({callback: $.noop}, defaults || {}, options || {});
        },
        resultOf: function (value) {
          if (_.isNull(value))     return '';
          if (_.isString(value))   return value;
          if ($.isArray(value))    return _.map(value,function(x){return $.fluxx.util.resultOf(x)}).join('');
          if ($.isFunction(value)) return arguments.callee(value.apply(value, _.tail(arguments)));
          if (_.isString(value.jquery))
            return $.fluxx.util.getSource(value);
          return value;
        },
        iconImage: function(name) {
          return $.fluxx.config.icon_path + '/' + name + '.png';
        },
        marginHeight: function($selector) {
          return parseInt($selector.css('marginTop')) + parseInt($selector.css('marginBottom'));
        },
        marginWidth: function($selector) {
          return ($selector.outerWidth(true) - $selected.width()) / 2;
        },
        itEndsWithMe: function(e) {
          e.stopPropagation();
          e.preventDefault();
        },
        logout: function() {
          window.sessionStorage.removeItem('user_credentials');
          $.removeCookie('user_credentials');
          window.location = '/logout?logout_source=explicit_logout2';
        },
        itEndsHere: function (e) {
          e.stopImmediatePropagation();
          e.preventDefault();
        },
        getSource: function (sel) {
          return _.map($(sel), function(i) { return $('<div>').html($(i).clone()).html();});
        },
        getTag: function (sel) {
          return _.map($(sel), function(i){return $('<div>').html($(i).clone().empty().html('...')).html()}).join(', ')
        },
        autoGrowTextArea: function(sel) {
          //AML: This has not worked properly in a long time. Causing major lag in Safari. Removing code for now.
          return sel.filter('textarea').each(function() {
            $(this).height(100);
          });
        },
        seconds: function (i) { return i * 1000; },
        minutes: function (i) { return i * 60 * 1000; },
        getDashCookie: function() {

          // pre release-192, we errantly used to set both paths.
          // this is to make sure existing "/dashboard" cookies are removed. they will never be reset.
          // (otherwise users would have to manually delete it after release-192 deploy.) 
          $.removeCookie('dashboard', { path: '/dashboard' });

          return $.cookie('dashboard') || localStorage.dashboard;
        },
        setDashCookie: function(dashUrl) {
          $.cookie('dashboard', dashUrl, { path: '/' });
          localStorage.dashboard = dashUrl;
        },
        retrieveEmailFromString: function(str) {
          var retrievedEmail = str.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
          if(retrievedEmail == null)
          {
            return str;
          }else
          {
            return retrievedEmail;
          }
        },
        retrieveDocusignContactElementBySelector: function(el , selectorId) {
          switch(selectorId)
          {
            case 'email-address': 
              return el.find('#model_document_sign_envelope_signer_email');
              break; 
            case 'fluxx-contact': 
              return el.find('#model_document_sign_envelope_signer_user');
              break; 
            case 'grant-contact':
              return el.find('#model_document_sign_envelope_signer_model_relationship');
              break;  
          }
        },
        retrieveDocusignContactElementBySelectorForAdmin: function(el , selectorId) {
          switch(selectorId)
          {
            case 'email_address': 
              return el.find('#model_document_type_docusign_signer_email');
              break; 
            case 'fluxx_contact': 
              return el.find('#model_document_type_docusign_signer_user_id');
              break; 
            case 'grant_contact':
              return el.find('#model_document_type_docusign_signer_model_relationship');
              break;  
          }
        },
        clearSelectedValueFromDocusignTypeControl: function() {
          switch($('.docusign_recipient_type').val())
          {
            case 'email-address': 
              $('#model_document_sign_envelope_signer_email').val("");
              break;  
            case 'fluxx-contact': 
              $("#model_document_sign_envelope_signer_user").val("");
              break; 
            case 'grant-contact':
              $("#model_document_sign_envelope_signer_model_relationship")[0].selectedIndex = 0;
              break;  
         }
       }
      },
      ajax: function(req) {
        // This allows us to directly load HTML from the current page instead of doing and AJAX call
        if ((req.url.indexOf('#') == 0)  || (req.url.indexOf('.') == 0)){
          // Scope any class selectors to the current area only.
          var elements = (req.url.indexOf('#') == 0) ? $(req.url).html() : req.area.fluxxCard().find(req.url)[0].outerHTML,
              data = '<div id="card-body"><div>' + elements + '</div></div>',
              xhr = {status: 200},
              status;
          req.beforeSend();
          req.complete();
          req.success(data, status, xhr);
          return this;
        } else if (req.area && $.fluxx.useStencil(req)) {
          var newReq = $.extend({}, req);

          // Render a stencil
          if (newReq.beforeSend) newReq.beforeSend();
          var formType = newReq.url.split('/').pop(), extraData = {}, translatorPortalParam = false;
          if (req.data && $.isArray(req.data)) {
            var hidden_event_action = _.select(req.data, function(item) { return item.name == 'hidden_event_action'; });
            if (hidden_event_action[0]) extraData.hidden_event_action = hidden_event_action[0].value;
            _.each(req.data, function(keyValuePair) { 
              if(_.isEqual(keyValuePair, {name: "translator_portal", value: "1"})) {
                translatorPortalParam = true;
              }
            });
          }
          formType = formType.split('?');

          var params = $.fluxx.unparam(formType[1]);
          formType = formType[0];

          if (!params.show_of_tab_satellite_org) {
            newReq.url = newReq.url.split('?').shift();
          }
          if (params.event_action) {
            formType = 'event';
            extraData = {event_action: params.event_action, note_param: params.note_param};
          } else if (formType == 'new') {
            extraData = params;
          } else if (params.view == 'filter') {
            formType = 'filter';
            extraData = params;
            if (params.type) newReq.url = '/' + params.type + 's';
          } else if (('/' + formType) == newReq.url) {
            formType = 'list';
            extraData = params;
          } else {
            formType = (formType == 'edit' ? 'form' : 'show');
            extraData = params;
          }
          if (req.area.fluxxCard().data('native_view')) extraData.native_view = '1';
          if (req.url.match(/generic_templates/)) extraData = req.data;
          if (translatorPortalParam) extraData.translator_portal = '1';
          newReq.area.stencil({
            data: extraData,
            modelType: $.fluxx.extractModelTypeFromURL(newReq.url),
            modelID: $.fluxx.modelID(newReq.url),
            formType: formType,
            loadingHTML: null,
            request: newReq,
            upTaskId: params.upTaskId,
            searchExistingCard: req.searchExistingCard,
            callBack: function(data, $area, xhr, status) {
              if (newReq.complete) newReq.complete();
              if (newReq.success) newReq.success(data, status, xhr);
            }
          });
          return null;
        } else {
          $.fluxx.scrubScriptTagOf('user', req);
          return $.ajax(req);
        }
      },
      scrubScriptTagOf: function(targetType, req) {
        var modelType = targetType,
            pattern = new RegExp("^" + modelType + "\\[.*\\]$");

        if (req.data) {
          $.each(req.data, function(i, obj) {
            // JWS [31531][31066] keep scope limited
            if (obj !== null && typeof obj === 'object') {
              if (obj.name && obj.name.match(pattern) && obj.value) {
                obj.value = $.fluxx.sanitizeScriptTag(obj.value);
              }
            }
          });
        }
      },
      useStencil: function(req) {
        //AML: Hard coding for now: don't use stencil to list generic templates
        if (!req || !req.url) return false;
//        if (req && req.data && req.data[0] && req.data[0].name == 'unlock') return false;
        req.url = req.url.replace(/^(http|https):\/\/.*?\//, '/');
        if (req.url == '/generic_templates?skip_wrapper=true') return false;
        if (req.url == '/generic_templates?skip_wrapper=true') return false;
        if (req.url.match(/llm_summarize$|llm_summary$/)) return false;
        if (req.type && req.type != 'get' && req.type != 'GET') return false;
        var url = req.url.split('?').shift();

        var badParam = function(url) {
          // AML: Should we just not use stencils anywhere there is a url parameter?
          var params = $.fluxx.unparam(url.split('?')[1]),
              bad = false;
          _.each(['skip_stencil', 'inline', 'connect_user', 'connect_organization', 'connect_request', 'slim', 'multi', 'bulk_action', 'submenu', 'view_states', 'mnl_modal', 'reschedule', 'reschedule_bulk_edit'], function(p) {
            if (params[p]) bad = true;
          });
          return bad;
        };

        return !url.match(/\.json$/) && $.fluxx.config.stencils_for.indexOf(url.split('/')[1]) != -1 && !$.fluxx.hasView(req) && !badParam(req.url)
      },
      hasView: function(req) {
        // Don't use stencils if we are looking at a different card view, or the workflow map
        // This function is getting messy, may be time for a refactor
        return _.select(req.data || [], function(item) {
          // Pretend the grant promotion interstitial is a view, so stencils won't pick it up and try to render
          if (item) return $.fluxx.config.card_views.concat(['approve_grant_details']).indexOf(item.name) != -1;
        }).length > 0
      },
      extractModelTypeFromURL: function(url) {
        var modelTypePlural = url.split('/')[1];
        // AML TODO: Maybe use another inflector like https://github.com/jeremyruppel/underscore.inflection
        if (modelTypePlural.match(/ies$/)) {
          // funding_source_allocation_authorities -> funding_source_allocation_authority
          return modelTypePlural.replace(/ies$/, 'y');
        }
        return modelTypePlural.replace(/s$/, '');
      },
      modelID: function(url) {
        return url.split('/')[2];
      },
      generateUUID: function() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = (d + Math.random()*16)%16 | 0;
          d = Math.floor(d/16);
          return (c=='x' ? r : (r&0x7|0x8)).toString(16);
        });
        return uuid;
      },
      logOn: true,
      log: function () {
        if (!$.fluxx.logOn) return;
        if (typeof console == 'undefined') {
          $.fluxx.logOn = false;
        } else {
          if (! this.logger) this.logger = (console.log ? _.bind(console.log, console) : $.noop);
          _.each(arguments, _.bind(function(a) { this.logger(a) }, this));
        }
      },
      addPlugin: function(plugin) {
        if (!$.my.pluginsToLoad) $.my.pluginsToLoad = [];
        if ($.my.pluginsToLoad.indexOf(plugin) == -1) $.my.pluginsToLoad.push(plugin);
      },
      setVal: function($elems, val) {
        $elems.each(function() {
          var $elem = $(this);
          if ($elem.is(':input'))
            $elem.val(val);
          else
            $elem.html(val);
          if ($elem.hasClass('amount'))
            $.fluxx.formatCurrency($elem);
        });
      },
      convertCurrencyToFloat: function(currencyString){
        var number = parseFloat(currencyString.replace(/[^\d\.]+/g, ''));
        return _.isNaN(number) ? 0.0 : number;
      },
      formatCurrency: function($elem, f) {
        if (!f) f = $.fluxx.config.currency_format;
        if (typeof $elem != 'object') {
          $elem= $('<div>' + $elem + '</div>');
          $elem.formatCurrency({decimalSymbol: f.separator, symbol: f.unit, roundToDecimalPlace: f.precision, digitGroupSymbol: f.delimiter, negativeFormat: '%s-%n'});
          return $elem.text();
        } else {
          // 14034, Bin Lu: abs value of scientific notation that less than 0.01 need to be round as zero.
          //                Otherwise Jquery formatCurrency will format it wrong, since it does split on dot.
          //                e.g. 7.275957614183426e-12 ==> 7
          if (Math.abs($elem.text()) < 0.01) {
            $elem.text(0);
          }
          $elem.formatCurrency({decimalSymbol: f.separator, symbol: f.unit, roundToDecimalPlace: f.precision, digitGroupSymbol: f.delimiter, negativeFormat: '%s-%n'});
        }
      },
      formatDate: function($elem) {
        var d = new Date($elem.text());
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
        var val = $.datepicker.formatDate($.fluxx.config.date_format, d);
        $elem.text(val);
      },
      sessionData: function(key, value) {
        if (window.sessionStorage)
          if (value)
            window.sessionStorage[key] = value;
          else if (key)
            return window.sessionStorage[key];
      },
      unparam: function(query) {
        var query_string = {};
        if (typeof query == "string") {
          var vars = query.split("&");
          for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            pair[0] = decodeURIComponent(pair[0]);
            pair[1] = decodeURIComponent(pair[1]);
                // If first entry with this name
            if (typeof query_string[pair[0]] === "undefined") {
              query_string[pair[0]] = pair[1];
                // If second entry with this name
            } else if (typeof query_string[pair[0]] === "string") {
              var arr = [ query_string[pair[0]], pair[1] ];
              query_string[pair[0]] = arr;
                // If third or later entry with this name
            } else {
              query_string[pair[0]].push(pair[1]);
            }
          }
        }
        return query_string;
      },
      unparamToArray: function(query) {
        var array = [];
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          pair[0] = decodeURIComponent(pair[0]);
          pair[1] = decodeURIComponent(pair[1]).replace(/\+/g, ' ');
          array.push({name: pair[0], value: pair[1]});
        }
        return array;
      },
      /** Wrapper around url parameter retrieval method defined here: https://stackoverflow.com/a/901144 */
      safeGetParameterByName: function(name, url) {
        try {
          name = name.replace(/[\[\]]/g, '\\$&');
          var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
              results = regex.exec(url);
          if (!results) return null;
          if (!results[2]) return '';
          return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }
        catch(e) {
          console.error('Error in safeGetParameterByName, cannot parse or retreive parameter', e);
          return null; // sensible default to any error here
        }
      },
      /**
       * Detect and "pass through" a token parameter to another url that needs it for authentication
       * 
       * Used with Office Add-ins in the context where a authentication token is passed to certain partials/pages (like the
       * advanced conditional builder) and those pages call subsequent endpoints that also need the token passed through to them
       * in order to authenticate via token instead of cookies. Otherwise, we get unauthorized errors.
       * 
       * @param {boolean} [isFirst] - If true, the first parameter is being added to the url, so use "?" instead of "&"
       */
      generateTokenParam: function(isFirst) {
        var qOrAmp = isFirst === true ? "?" : "&";  
        var token = this.safeGetParameterByName("token", window.location.href);
        return token ? (qOrAmp + "token=") + token : "";
      },
      scrollBarWidth: function() {
        document.body.style.overflow = 'hidden';
        var width = document.body.clientWidth;
        document.body.style.overflow = 'scroll';
        width -= document.body.clientWidth;
        if(!width) width = document.body.offsetWidth - document.body.clientWidth;
        document.body.style.overflow = '';
        return width;
      },
      authenticityTokenParam: function() {
        var authenticityTokenObject = {};
        var authenticityKey = $("meta[name=csrf-param]").attr("content");
        var authenticityToken = $("meta[name=csrf-token]").attr("content");

        authenticityTokenObject[authenticityKey] = authenticityToken;

        return $.param(authenticityTokenObject);
      },
      requestCombinedPdfs: function($link) {
        $link.hide();
        $link.after($('<p class="temp-message">' + I18n.t('component.model_documents_component.loading')+ '</p>'));

        $.post($link.attr('href')).done(function(data) {
          alert(data.message || data.error);

          if (data.error) {
            $link.parent().find('.temp-message').remove();
            $link.show();
          } else {
            $link.parent().find('.temp-message').text(I18n.t('component.model_documents_component.refresh_confirmation'));
          }
        });
      },
      loadGuideStarModalData: function($elem, pageIncrement) {
        $elem.addClass('loading updating');
        if (pageIncrement != 0)
          $elem.attr('data-src', $elem.attr('data-src').replace(/\&pagenum=(\d+)$/, function(a,b){
            var pagenum = parseInt(b) + pageIncrement;
            return '&pagenum=' + pagenum;
           }));
  
        $.ajax({
          url: $elem.attr('data-src'),
          success: function(data, status, xhr){
            $elem.closest('.search-overlay.ui-tabs.ui-widget').scrollTop(0);
            $elem.replaceWith(data);
          }
        });
      },
      errorHtml: function(errorText) {
        errorText = errorText || 'Errors were found. Error messages are displayed near each form field below. '
        return '<div class="notice error"><a class="close-parent" href="#fluxx-card-notice"><img src="/images/fluxx_engine/theme/default/icons/cancel.png" alt="close"></a>' + errorText + '</div>'
      },
      sanitizeScriptTag: function(value) {
        return value.replace(/<script[^>]*>.*<\/script>/gi, '');
      },
      sanitizeHtml: function(value) {
        return value.replace(/(<([^>]+)>)/ig, '');
      },
      escapeString: function(name, options) {
        if (options && options['sanitizeScriptTag']) {
          name = this.sanitizeScriptTag(name);
        }
        return _.escape(name).replace(/&lt;/g, '').replace(/&gt;/g, '');
      },
      // These two functions are paired and are intended to be used in conjunction with the SimpleModal plugin
      limitTabbable: function() {
        var $area = $('#simplemodal-container');
        var $tabbableElementsOutsideModal = $(':tabbable').not($area.find(':tabbable'));
        $tabbableElementsOutsideModal.attr('tabindex', '-1').addClass('untabbable');
        $('.simplemodal-close').attr({
          'aria-label': 'Close',
          'role':'dialog'
        });
      },
      unlimitTabbable: function() {
        $('.untabbable').removeAttr('tabindex').removeClass('untabbable');
      },
      // Manually add a simplemodal-close button allow a user to tab to it
      addTabbableCloseButton: function () {
        var $closeBtn = $("<a class='simplemodal-close'></a>")
        $('.simplemodal-data').append($closeBtn)
        $closeBtn.click(function () {$.modal.close();})
                  .keypress(function (event) {
                    var keycode = (event.keyCode ? event.keyCode : event.which);
                    if (keycode == '13') {$.modal.close();}
                  });
        $closeBtn.attr({
          'aria-label': 'Close',
          'role': 'dialog',
          'tabindex': '0'
        });
      },
      setQueryStringParam: function(url, name, value) {
        // Unreserved characters: https://tools.ietf.org/html/rfc3986#section-2.3
        // Let's be extra strict in invalid_names, to simplify later regex that uses `name`
        var invalid_names  = /[^A-Za-z0-9_]/,
            invalid_values = /[^A-Za-z0-9-._~]/;
        if (!name || invalid_names.test(name)) {
          throw 'setQueryStringParam: Invalid name: ' + name;
        } else if (invalid_values.test(value)) {
          throw 'setQueryStringParam: Invalid value: ' + value;
        }

        // Regex for whole URL up to and including our parameter, but nothing after
        var regex = new RegExp('(^.+[?&]' + name + '=)[^&]*');
        if (regex.test(url)) {
          return url.replace(regex, '$1' + value);
        } else {
          var url_has_query_string = url.indexOf('?') >= 0;
          return url + (url_has_query_string ? '&' : '?') + name + '=' + value;
        }
      }
    }
  });

  var DONT_QUICK_POLL_IF = /^\/(client_stores\/|realtime_updates|lock\/)/;

  $('html').ajaxComplete(function(e, xhr, options) {
    // [13969] handle gracefully unauthorized XHRs that were previously getting
    // handled via redirect on backend and bombing the frontend
    if ((xhr.status === 401) && (xhr.getResponseHeader('X-Fluxx-Reason') === 'unauthorized')) {
      window.location.href = '/';
    }

    var resetCSRF = xhr.getResponseHeader('X-Fluxx-Reset-CSRF');
    if (resetCSRF) $("meta[name=csrf-token]").attr("content", resetCSRF);

    // AML: Make Fluxx more responsive by looking for RTUs more rapidly after a DELETE or POST
    if (!$.fluxx.config.rtu_websocket_enabled &&
        (options.type == 'DELETE' || options.type == 'POST' || options.type == 'PUT') &&
        (options.url && !options.url.match(DONT_QUICK_POLL_IF))) {
      if ($.my.fluxx && $.my.fluxx.quickRTUcount) {
        // Another write has happened, so look for the RTU
        $.my.fluxx.quickRTUcount = $.fluxx.config.rtu_quick_poll_max_count;
      } else if ($.my.fluxx) {
        // Delay between faster RTUs
        var delay = $.fluxx.util.seconds(4);
        // Maximum number of times to look before we seen any RTU
        // TODO: In the future, we could make sure the RTU is specific to the actual ajax call
        $.my.fluxx.quickRTUcount = $.fluxx.config.rtu_quick_poll_max_count;
        var pollFunc = function () {
          setTimeout(function() {
            if (--$.my.fluxx.quickRTUcount > 0) {
              console.log("Quick poll #"+(8-$.my.fluxx.quickRTUcount));
              $.fluxx.realtime_updates.poll();
              pollFunc();
            }
          }, delay);
        };
        pollFunc();
      }
    }
    if ($.cookie('user_credentials'))
      $.fluxx.sessionData('user_credentials', $.cookie('user_credentials'));
    // Look for a HTTP response header called fluxx_template. If it has a value of login we are not logged in.
    if (xhr.getResponseHeader('fluxx_template') == 'login')
      window.location = "/logout?logout_source=server_logout";

    var lastRequestAt = xhr.getResponseHeader("X-Last-Request-At");
    if (lastRequestAt) {
      sessionTimeout.updateLastRequestTime(parseFloat(lastRequestAt));
    }

  }).ajaxSend(function(e, xhr, options) {
    xhr.setRequestHeader( 'X-CSRF-Token', $('meta[name=csrf-token]').attr('content') );
    if($.fluxx.config.sl_product) { // only streamline product is tracked
      if( (options['type'] === 'POST') && (options['url'].indexOf('organizations.json') > 0) ) {
        Tracker.event($.fluxx.config.sl_product + ' : Create Org', {
          'product'   : $.fluxx.config.sl_product,
          'category' : 'Create Org'
        });
      } else if( (options['type'] === 'POST') && (options['url'].indexOf('users.json') > 0) ) {
        Tracker.event($.fluxx.config.sl_product + ' : Create User', {
          'product'   : $.fluxx.config.sl_product,
          'category' : 'Create User'
        });
      }
    }

    // Make all URLS local except when in universal portal.
    if (!$.my || !$.my.universal_portal) {
		  options.url = options.url.replace(/^http\:\/\/[^/]+/, '');
    }

    if (!$.cookie('user_credentials')) {
      if ($.fluxx.sessionData('user_credentials')) {
        $.fluxx.log("user_credentials cookie set from local session store");
	      $.cookie('user_credentials', $.fluxx.sessionData('user_credentials'), {path: '/'});
        // Cookie has been lost so session will be lost
        // Use value stored in session store and do a synchronous ajax call to restore the cookie.
        jQuery.ajax({
          url: window.location.href,
          async:   false,
          success: function() {
            $.noop;
          }
        });
      }
    }
  });

  if (typeof keyMaster != 'undefined') {
    keyMaster("⌘+shift+space, ctrl+shift+space", function () {
      $(".universal-search").click();
    });
  }

  $.fn.charCount = function(options){

    // default configuration properties
    var defaults = {
      allowed: 140,
      warning: 25,
      css: 'counter',
      counterElement: 'span',
      cssWarning: 'limit_warning',
      cssExceeded: 'limit_exceeded',
      counterText: I18n.t('js.message.characters_left_for_field') + ' '
    };

    var options = $.extend(defaults, options);

    function calculate(obj, $counter, count){

      if (count == null) {
        count = $.fn.getCharacterCount(obj);
      }

      var available = options.allowed - count;
      if(available <= options.warning && available >= 0){
        $counter.addClass(options.cssWarning);
      } else {
        $counter.removeClass(options.cssWarning);
      }
      if(available < 0){
        $counter.addClass(options.cssExceeded);
      } else {
        $counter.removeClass(options.cssExceeded);
      }
      if (!$counter.hasClass(options.css)){
        $counter = $counter.next();
      }
      $counter.html(options.counterText + available);
    };

    this.each(function() {
      var $elem = $(this);

      if ($elem.data('charCount'))
        return;
      $elem.data('charCount', true);

      setTimeout(function() {
        var $counter = $('<'+ options.counterElement +' class="' + options.css + '">'+ options.counterText +'</'+ options.counterElement +'>'),
            editor = $elem.tinymce();
        if (editor) {
          $elem.after($counter);
          editor.on('input propertychange change click keyup paste cut', function(ed, e) {
            var body = editor.getBody(),
              text = body.innerText || body.textContent;
            calculate($elem, $counter, text.length);
          });
          editor.fire('KeyUp', editor, {keycode: 0});
        } else {
          $elem.after($counter);
          $elem.on('input propertychange change click keyup paste cut', function(){calculate(this, $counter)});
          calculate($elem, $counter);
        }
      }, $elem.is(':visible') ? 400 : 1200);
    });
  };

  $.fn.groupCharCount = function(options){

    // default configuration properties
    var defaults = {
      allowed: 140,
      warning: 25,
      css: 'counter',
      counterElement: 'span',
      cssWarning: 'limit_warning',
      cssExceeded: 'limit_exceeded',
      counterText: I18n.t('js.message.characters_left_for_group') + ' '
    };

    var options = $.extend(defaults, options);

    function groupCalculate(options){
      var total = 0,
          $textareas = options.groupElement.find('textarea');

      // Calculate the total number of chars in each textarea in this group
      $textareas.each(function() {
        var $elem = $(this),
            editor = $elem.tinymce();
        if ($elem.data('richText')) {
          var $t = $('<div>' + jq22(this).data('redactor').code.get() + '</div>');
          total += $t.text().length;

        } else if (editor) {
          var body = editor.getBody(),
              text = body.innerText || body.textContent;
          total += text.length;
        } else {
          var $t = $('<div>' + $elem.val() + '</div>');
          total += $t.text().length;
        }
      });

      var available = options.allowed - total,
          $counter = $('<'+ options.counterElement +' class="' + options.css + ' group_char_limit">'+ options.counterText + available +'</'+ options.counterElement +'>');

      if(available <= options.warning && available >= 0){
        $counter.addClass(options.cssWarning);
      } else {
        $counter.removeClass(options.cssWarning);
      }

      if(available < 0){
        $textareas.addClass(options.cssExceeded);
        $counter.addClass(options.cssExceeded);
      } else {
        $textareas.removeClass(options.cssExceeded);
        $counter.removeClass(options.cssExceeded);
      }
      options.groupElement.find('.group_char_limit').remove();
      $textareas.each(function() {
        $(this).after($counter.clone());
      });
    }

    this.each(function() {
      var $elem = $(this);
      options.groupElement = $elem;

      if ($elem.data('charCount'))
        return;
      $elem.data('charCount', true);

      setTimeout(function() {
        $elem.find('textarea').each(function() {
//          var $counter = $('<' + options.counterElement + ' class="' + options.css + '">' + options.counterText + '</' + options.counterElement + '>'),
            var $ta = $(this),
            editor = $ta.tinymce();
          if ($ta.data('richText')) {
            $ta.data('richText').$elem.on('change.callback.redactor', function() {
              groupCalculate(options);
            });

          } else if (editor) {
            editor.on('input propertychange change click keyup paste cut', function (ed, e) {
              groupCalculate(options);
            });
          } else {
            $ta.on('input propertychange change click keyup paste cut', function () {
              groupCalculate(options);
            });
          }
        });
        groupCalculate(options);
      }, $elem.is(':visible') ? 400 : 1200);
    });
  };

  $.fn.acceptOnlyNumbers = function() {
    this.on('input', function() {
      var value = this.value;
      value = value.replace(/[^0-9.]/, "");
      if (value.indexOf(".") !== -1) {
        var beforeDecimal = value.substring(0, value.indexOf("."));
        var afterDecimal = value.substring(value.indexOf(".") + 1);
        afterDecimal = afterDecimal.replace(/\./, "");
        var decimalPlaces = parseInt($(this).data('decimal-places'));
        if (decimalPlaces) {
          afterDecimal = afterDecimal.substring(0, decimalPlaces)
        }
        value = beforeDecimal + "." + afterDecimal;
      }
      var maxValue = $(this).data('max');
      if (maxValue && value > parseInt(maxValue)) {
        this.value = value.slice(0, -1);
      } else {
        this.value = value;
      }
    })
  };

  var adjustHeight = function($area) {
    // wrap to make potentially expensive reflow async
    // to leave space for potentially more critical code path
    // (especially during areaDetailTransform where lots of these
    // fire in a row)
    window.setTimeout(function() {
      $area.css({
        overflow: 'visible',
        height: '',
        width: '',
        opacity: ''
      });
      var $card = $area.fluxxCard(),
          $nav = $card.find('.multistep-navigation'),
          $content = $card.find('.multistep-content'),
          adjustHeight = $area.height() + 20;
    
      $content.height($content.height() + ($area.is(':visible') ?  adjustHeight : -adjustHeight));
      if ($nav.height() > $content.height()) $content.height($nav.height() + 16);
    });
  };

  $.fn.revealIf = function() {
    var currentCond = {
      'Yes': 'True',
      'No': 'False',
    };

    var dependents = [];
    $(this).each(function() {
      // these elements are hidden to start if data-reveal-if is included.
      var $area = $(this);

      if ($area.data('revealIfSetup')) return;

      var modelType = $area.data('model-type'),
          usingStencils = modelType != undefined,
          condition = usingStencils ? $area.data('reveal-if') : $area.attr('data-reveal-if').split(','),
          elemID = usingStencils ? undefined : condition.shift();

      // defaults to show for non-stencil usage if not provided.
      var hideType = usingStencils ? condition.type : $area.attr('data-reveal-if-type');

      if ((usingStencils && condition.attribute && condition.values) || (!usingStencils && condition[0] && elemID)) {

        //TODO: How about radio buttons

        var elemIDPattern;
        if ($.fluxx.config.dashboard_type == "portal_v1" || $.fluxx.config.dashboard_type == "lite"){
          elemIDPattern = '[data-original-id="' + elemID + '"]'
        }else{
          elemIDPattern = '#' + elemID;
        }
        var $dependentOn = usingStencils ?
          $area.fluxxCardArea().find('[name="' + modelType + '[' + condition.attribute + ']"],[name="' + modelType + '[' + condition.attribute + '][]"]').not('[type="hidden"]') :
          $area.fluxxCardArea().find(elemIDPattern).not('[type="hidden"]');
        if (!$dependentOn[0]) $dependentOn = $(this).fluxxCardArea().find('#' + elemID + '_input.radio:first input[type="radio"]');

        $area.data('revealIfSetup', true);
        if (usingStencils) {
          // AML: When we are in show mode, the value will been passed in condition.current
          var has_value = $.fn.hasValue(condition, condition.current);

          // legacy case - Transform Yes -> True and No -> False for Unknown client??
          if (!has_value && (condition.current == 'Yes' || condition.current == "No")) {
            has_value = $.fn.hasValue(condition, (condition.current == 'Yes' ? 'True' : condition.current == 'No' ? 'False' : condition.current));
          }

          // all these elements are hidden by default, so we need to show the element if the value doesn't exist and the behavior is hide
          var is_show = (hideType == "show"),
              is_hide = (hideType == "hide");
          if ((has_value && is_show) || (!has_value && is_hide)) {
            $area.show();
            $area.find(':input').filter(function () { return $(this).attr("type") == 'hidden' }).prop('disabled', false);
          } else if ((has_value && is_hide) || (!has_value && is_show)) {
            $area.hide();
            // We need to grab the hidden fields, but extra care is needed for hidden fields in autocompletes
            $area.find(':input').filter(function () {
              // dont care about non hidden fields
              if ($(this).attr("type") !== 'hidden') {
                return false;
              }
              // We use hidden fields for autocompletes to hold the foreign key while the other input has the autocomplete text
              // We need to double check that if these are for autocompletes that both fields are marked hidden
              if ($area.html().match(/data-autocomplete/)) {
                var inputsSameId = $area.find('input#'+$($area.find(':input').filter(function () { return $(this).attr("type") === 'hidden' })[0]).attr('id'));
                // we only return true to disable if all inputs are hidden
                return inputsSameId.filter(function() { return $(this).attr('type') !== 'hidden'}).length === 0;
              } else {
                return true;
              }
            }).prop('disabled', true);
          }
          if (!$dependentOn[0]) { return $area; }
        }

        // need a guaranteed way to uniquely identify each $dependentOn elem for grouping
        $dependentOn.data('dependentOnUuid') || $dependentOn.data('dependentOnUuid', $.fluxx.generateUUID())
        dependents.push({
          usingStencils: usingStencils,
          condition: condition,
          hideType: hideType,
          $area: $area,
          $dependentOn: $dependentOn
        });
      }
    });
    var groupedByDependentOn = _.groupBy(dependents, function(dependent){
      return dependent.$dependentOn.data('dependentOnUuid');
    });
    return _.each(groupedByDependentOn, function(dependents, _dependentOnId) {
      // these are already grouped so we can safely assume the first in the group
      // is the same for all
      var $dependentOn = dependents[0].$dependentOn;
      $dependentOn.change(function() {
        var $e = $(this);
        if ($e.is(':radio') && !$e.is(':checked')) return;
        _.each(dependents, function(dependent) {
          
          var usingStencils = dependent.usingStencils;
          var condition = dependent.condition;
          var hideType = dependent.hideType;
          var $area = dependent.$area;

          var show = $e.hasValue((usingStencils ? condition : {values: condition}), undefined);
          if (hideType == 'hide') show = !show;

          if (show) {
            $area.show();
            adjustHeight($area);

            // This is to check if a group has any visible 'reveal-if' parent.
            var $parentRevealIf = $area.parents('[data-reveal-if]');
            var parentRevealIfIsShown = $parentRevealIf[0] && $parentRevealIf.is(':visible');

            if (!$parentRevealIf[0] || parentRevealIfIsShown) {
              $area.find(':input')
                .not(function() {
                  // This finds all the input element inside a nested 'reveal-if' that is hidden so they
                  // can be filtered out with the .not() and remain disabled.
                  $area.find('[data-reveal-if]').not(function() {
                    var cond = $(this).data('reveal-if');
                    return cond.current ? $.fn.hasValue(cond, currentCond[cond.current]) : false
                  }).find(':input'); /* don't re-enable for inputs inside child if/then elements */
                })
                .not(function() {
                  // This is to check if a group has a DIRECT visible 'reveal-if' parent.
                  var firstParentVisible = $($(this).parents('[data-reveal-if]')[0]).is(':visible');
                  return !firstParentVisible;
                })
                .not(function() {
                  return $(this).data("readonly") === true || $(this).hasClass("select-transfer-original");
                })
                .prop('disabled', false);
              
              $area.find(".input-mask").each(function() {
                $(this).fluxxInputMask();
              });
            }
          } else {
            $area.hide();
            adjustHeight($area);
            $area.find(':input').prop('disabled', true);
          }
        });
      }).change();
    });
  };

  $.fn.booleanLabelsMatch = function (condition, current_val, expected_val) {
    var true_label = _.trim(condition.boolean_true_label.toLowerCase());
    var false_label = _.trim(condition.boolean_false_label.toLowerCase());

    if (current_val) current_val = current_val.toLowerCase();
    if (expected_val) expected_val = expected_val.toLowerCase();

    // yes & no are the default labels when:
    // 1- no label is configured
    // 2- or, the group is lazy loaded
    // so use the actual true and false label here to make it easy to compare current and expected values
    if (current_val == 'yes') current_val = true_label;
    if (current_val == 'no') current_val = false_label;

    // another special case when no labels are configured (yes and no are the default labels)
    // but the client used true/false or yes/no to configure group visibility
    if ((expected_val == 'true' || expected_val == 'yes') && current_val == true_label) expected_val = true_label;
    if ((expected_val == 'false' || expected_val == 'no') && current_val == false_label) expected_val = false_label;

    return expected_val == current_val && (expected_val == true_label || expected_val == false_label);
  }

  $.fn.hasValue = function (condition, passedVal) {
    // values represent the appropriate choices for the show logic, passedVal is either the currentCondition or nil (in which case we go based off of the $elem)
    var $elem = $(this),
        show = false,
        vals = $.isArray(condition.values) ? condition.values.slice() : [condition.values];
    while (!show && vals.length) {
      var val = _.trim(vals.pop().toLowerCase());
      if ($elem.is('select')) {
        $elem.find(":selected").each(function () {
          if (condition.is_reveal_if_attribute_boolean) {
            var current_val = condition.use_select_value ? $(this).val() : $(this).text();
            show = show || $.fn.booleanLabelsMatch(condition, _.trim(current_val), val);
          } else {
            show = show || (_.trim((condition.use_select_value ? $(this).val() : $(this).text()).toLowerCase()) == val);
          }
        });
      } else if ($elem.is(':checkbox')) {
        if (condition.is_reveal_if_attribute_boolean) {
          var current_val = $elem.is(':checked') ? 'yes' : 'no';
          show = $.fn.booleanLabelsMatch(condition, current_val, val)
        } else {
          val = (val && val != '' && val != 'false');
          show = val == $elem.is(':checked');
        }
      } else if ($elem.is(':radio')) {
        show = (val == _.trim($elem.parent().text()).toLowerCase());
      } else {
        var current_val = (passedVal !== undefined) ? passedVal : $elem.val();

        // when this group is based on boolean element 
        if (condition.is_reveal_if_attribute_boolean) {
          return $.fn.booleanLabelsMatch(condition, current_val, val);
        }

        // If the passed value is given (detail view) and the value contains a comma lets see if any single values in the comma separated list matches the show condition
        if (current_val && current_val.indexOf(",") != -1 && val && val.indexOf(",") != -1) {
          // val contains commas, so we cant split val by commas. try to regex match.
          return current_val.match(new RegExp(val.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), 'i'))
        }
        if (passedVal && current_val.indexOf(",") != -1) {
          _.each(current_val.split(","), function(value){
            var possible_val = _.trim(value.toLowerCase());
            if (possible_val == val){
              show = true;
            }
          });
          if (show === true) { return show; }
        }

        // if passedVal is an array of values
        if (passedVal && $.isArray(passedVal)) {
          _.each(passedVal, function (value) {
            if (_.trim(value.toLowerCase()) == val) {
              show = true;
            }
          });
          if (show === true) { return show; }
        }

        if (current_val && typeof current_val == 'string') {
          current_val = _.trim(current_val.toLowerCase());
        }
        show = (current_val == val);
      }
    }
    return show;
  };

//  jQuery.fx.interval = 2;
})(jQuery);
