(function($){
  $.fn.extend({
    initValidator: function() {
      var $stencil = $('.render-stencil.loading');
      if ($stencil[0]) {

        // Wait for legacy components to load
        $stencil.on('loaded.fluxx.stencil', function () {
          if ($stencil.hasClass('loading')) return;
          $.fn.initValidator();
        });
        return;
      }
      var rules = {};
      // AML: Hidden input can mess up the validator
      $('.required input[type=hidden]').each(function() {
        var $input = $(this);
        // GM-43479 dont do this for autocomplete hidden fields holding real value
        if ($input.parent().attr('class').match(/autocomplete/) && $input.siblings('input').length === 1 && $input.siblings('input')[0].name === $input.attr('name')) {
          return;
        }
        $input.attr('name', $input.attr('name') + '_temp');
      });

      $('.required').each(function() {
        var $input = $(this).find(':input:not([type=hidden])');
        if ($input.attr('name')!== undefined) {
          rules[$input.attr('name')] = {required: true, email: ($input.attr('name').match(/email/) != null)};
        }
      });

      $(':input[data-char-limit]').each(function() {
        var $input = $(this);
        var rule = {};
        var max_length_key = $input.hasClass('wysiwyg') ? 'maxlengthExt' : 'maxlength';
        rule['required'] = $input.hasClass('required') || $input.parents('li.text, li').hasClass('required');
        rule[max_length_key] = $input.data('char-limit');
        rules[$input.attr('name')] = rule;
      });

      $('.edit form:first input:submit').click(function(e) {
        try {
          tinyMCE.triggerSave();
        } catch(err) {

        }
      });

      $('.edit form:first').validate({
        rules: rules,
        errorPlacement: function(error, element) {
          if (element.is(':radio')) {
            error.appendTo(element.parents('fieldset:first'));
          } else {
            error.appendTo(element.parents(".input:first"));
          }
        },
        invalidHandler: function(event, validator) {
          var errors = validator.numberOfInvalids() + $(this).invalidInputs().length;

          if (errors) {

            $("#card-header").addClass("notice error").html('<a class="close-parent" href="#fluxx-card-notice"><img src="/images/fluxx_engine/theme/default/icons/cancel.png" alt="close"></a>' + I18n.t('js.message.form_text_error'));

            $("#card-header").find(".close-parent").click(function(e){
              e.preventDefault();
              $("#card-header").hide();
            });

            $('.step').removeClass('error').addClass('accepted');
            $.each(validator.invalidElements(), function() {
              var index = $(this).parents('div.multistep:last').data('multistep-index')
              $('.step:eq(' + index + ')').removeClass('accepted').addClass('error');
              $('.step.error:first').click();
            });

            if ($('.multistep-navigation').length > 0){
                var $form = $('form');
                var data = $form.serializeArray();

                // Send blank values for select transfers so that values can be blanked
                $form.find('.include-blank').each(function() {
                  if (!$(this).val())
                    data.push({name: this.name, value: ""});
                });

                var found= {};
                var out = [];
                $.each(data, function(i, obj) {
                  if (obj.value) {
                    if (obj.name.match(/\[\]$/)) {
                      out.push(obj);
                    } else {
                      if (!found[obj.name] || obj.value)
                        found[obj.name] = obj.value;
                    }
                  }
                });
                $.each(found,function(name, value) {
                  out.push({name: name, value: value});
                });
                out.push({name: "force_save", "value": "1"})
                $.ajax({
                  url: $form.attr('action'),
                  type: 'POST',
                  data: out,
                  error: function (jqXHR, textStatus, errorThrown) {
                    $.fluxx.log('Error', jqXHR, textStatus, errorThrown);
                  }
                });
            }
          }
        },
        submitHandler: function($form) {
          $('.required input[type=hidden]').each(function() {
            var $input = $(this);
            $input.attr('name', $input.attr('name').replace(/\_temp$/, ""));
          });

          // Avoid form re-submission
          var $submit = $($form).find('.input_action > input[type=submit]');

          $submit.attr('disabled', 'disabled');
          $submit.val('Submitting');

          $form.submit();
        }
      });
    }
  });

  // custom validator to strip html before checking length
  $.validator.addMethod("maxlengthExt", function(value, element, param) {
    var editor = $(element).tinymce();
    var str;
    if(editor)
      str = editor.getBody().innerText;
    else
      str = $(value).text();

    var count = $.trim(str).length;
    return  this.optional(element) || count <= param;
  }, "Please enter no more than {0} characters.");

})(jQuery);


$(document).ready(function() {
  $.fn.initValidator();
});
