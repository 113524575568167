(function($){

  var VALUE_DIGITS_LIMIT = 15
  var INVALID_ATTR = "currencyExpanderIsInvalid";
  var KMB_REGEX = "^([$])?([0-9]{1,15}|0)(,?[0-9]{3})?(,?[0-9]{3})?(,?[0-9]{3})?(\.[0-9]{1,2})?([kKmMbB])?$";
  var KMB_LETTERS = /^[kKmMbB]$/;
  var KMB_CONVERSIONS = {"k": 1000, "K": 1000,
    "m": 1000000, "M": 1000000, "b": 1000000000, "B": 1000000000 };

  function CurrencyExpander($el) {
    this.isMultiCurrencyClient = $.fluxx.config.use_base_currency_expander;
    this.$el        = $el;
    this.$inputWrap = this.$el.closest('.input');
    this.showingError = false;
    this.currencyExpanderHelpText   = this.$el.data("currency-expander-help-text");
    this.currencyExpanderOptions    = this.$el.data("currency-expander-options");
    this.addHelpText();
    this.init();
  }

  $.extend(CurrencyExpander.prototype, {
    ERROR_TIMEOUT: 3000,
    init: function() {
      if (this.$el.val()) {
        // already something there. don't mask unless user edits.
        this.$el.one('input', this.addCurrencyExpanderMask.bind(this));
      } else {
        this.addCurrencyExpanderMask();
      }
    },
    isKmbChar: function(chr) {
      return KMB_LETTERS.test(chr);
    },
    isNotKmbAlphabet: function(key) {
      var chr = String.fromCharCode(key),
          isAlphabet = /^[a-zA-Z]$/.test(chr),
          isKmb = this.isKmbChar(chr);

      return isAlphabet && !isKmb;
    },
    isDollarSign: function(key) {
      // JWS we need append '$' to conversion result on focusout
      // the regex has been adjusted to allow '$' first char
      var chr = String.fromCharCode(key),
          isDollarSign = /[$]/.test(chr);
      return isDollarSign;
    },
    sanitizeRegexEdgeCase: function() {
      // edge case value: "2p"
      // native onKeyValidate doesnt work. it lets it thru; hence isNotKmbAlphabet() func
      // this function replaces "2p" with "2"
      var val = this.$el.val();
      var val2 = val.slice(0,-1);
      this.$el.val(val2);
    },
    handleKeyValidation: function(key, result) {
      if (!result) {
        this.handleInvalidChar();
      } else if (this.isDollarSign(key)) {
        this.handleValidChar();
      } else if (this.isNotKmbAlphabet(key) || result.pos > VALUE_DIGITS_LIMIT - 1) {
        this.sanitizeRegexEdgeCase();
        this.handleInvalidChar();
      } else {
        this.handleValidChar();
      }
    },
    handleValidChar: function() {
      this.hideError();
      this.$el.removeData(INVALID_ATTR);
    },
    handleInvalidChar: function() {
      this.showError();
      this.$el.data(INVALID_ATTR, true);
    },
    addCurrencyExpanderMask: function() {
      this.$el.inputmask('Regex', {
        regex: KMB_REGEX,
        greedy: false,
        onKeyValidation: this.handleKeyValidation.bind(this),
      });
      this.$el.on('blur', this.handleBlur.bind(this));
    },
    addHelpText: function() {
      this.$inlineErr = $("<p>").addClass("inline-errors").addClass("input-mask-error").text(this.currencyExpanderHelpText);
      this.$inputWrap.append(this.$inlineErr.hide());
    },
    hideError: function() {
      if (!this.showingError) return;

      this.$inputWrap.removeClass("error");
      this.$inlineErr.hide();
      this.showingError = false;
    },
    showError: function() {
      if (this.showingError) return;

      this.$inputWrap.addClass("error");
      this.$inlineErr.show();
      this.showingError = true;
    },
    validInput: function() {
      var val = this.$el.val();
      if (val.length > 0) {
        return !!val.match(KMB_REGEX);
      } else {
        return false;
      }
    },
    formatWithCommas: function(value) {
      var roundedVal = value.toFixed(2);
      var parts = roundedVal.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var str = parts.join(".");
      return str;
    },
    handleKmbValue: function(buf) {
      var letter = buf[buf.length-1],
          whole = buf[2],
          decimal = buf[buf.length-2]
          newVal = "";

      if(decimal) {
        newVal = (parseFloat(whole) + parseFloat(decimal)) * KMB_CONVERSIONS[letter];
      } else {
        newVal = parseInt(whole) * KMB_CONVERSIONS[letter];
      }
      return newVal;
    },
    handleBlur: function() {
      if(this.validInput()) {
        var val = this.$el.val(),
            regexResult = val.match(KMB_REGEX),
            chr = regexResult.input[regexResult.input.length-1],
            isKmb = this.isKmbChar(chr),
            newVal;
        if (isKmb) {
          newVal = this.handleKmbValue(regexResult);
        } else {
          var noCommaNorDollarSignVal = regexResult[0].replace(/[,$]/g,'');
          newVal = parseFloat(noCommaNorDollarSignVal);
        }
        if(this.isMultiCurrencyClient === 'true') {
          this.$el.val(newVal);
        } else {
          this.$el.val("$" + this.formatWithCommas(newVal));
        }
      }
    },
    handlePaymentSchedulerBlur: function() {
      if(!this.validInput()) {
        return '';
      } else {
        var val = this.$el.val(),
            regexResult = val.match(KMB_REGEX),
            chr = regexResult.input[regexResult.input.length-1],
            isKmb = this.isKmbChar(chr),
            newVal;
        if (isKmb) {
          newVal = this.handleKmbValue(regexResult);
        } else {
          var noCommaNorDollarSignVal = regexResult[0].replace(/[,$]/g,'');
          newVal = parseFloat(noCommaNorDollarSignVal);
        }
        return newVal;
      } 
    }
  });

  function findInvalidInputs($el) {
    return $el.find('.currency-expander-mask').filter(function() {
      return $(this).data(INVALID_ATTR);
    });
  }

  $.fn.extend({
    fluxxCurrencyExpander: function() {
      return new CurrencyExpander(this);
    },
    doCurrencyExpanderForPaymentScheduler: function() {
      var ce = new CurrencyExpander(this);
      return ce.handlePaymentSchedulerBlur();
    }

  });
})(jQuery);
