(function($){
    $.richText = function(el, options){
      // To avoid scope issues, use 'base' instead of 'this'
      // to reference this class from internal events and functions.
      var base = this;

      // Access to jQuery and DOM versions of element
      base.$el = $(el);
      base.el = el;

      // Add a reverse reference to the DOM object
      base.$el.data("richText", base);

      base.init = function(){
        // TODO: DEM this isn't used at all, refactor base to use these defaults
        //base.options = $.extend({},$.richText.defaultOptions, options);

        base.cleanUpInput();
        base.$elem = jq22(base.$el);
        var originalVal = base.$elem.next().html(),
          hasEncodedValue = base.$elem.next().hasClass('text-content');

        if (hasEncodedValue) {
          if (!originalVal.match(/^\<p\>/)) originalVal = '<p>' + originalVal + '</p>';
          base.$elem.val(originalVal);
        }

        base.$el.removeClass("textarea-rte-loading");

        base.$elem.redactor({
          formatting: ['p', 'h6', 'h5', 'h4', 'h3', 'h2', 'h1'],
				  plugins: ['underline','supsub','alignment','undoredo','listed','theming'],
				  buttons: ['format', 'bold', 'italic', 'deleted'],
          linkSize: 2000,
          toolbarFixed: false,
          tabKey: false,
          regexps: {
             linkimage: /((https?|www)[^\\s]+\\.)(jpe?g|png|gif)(\\?[^\\s-]+)?/gi,
             url: /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi,
             // TODO: this is a hack to allow links for vimeo and youtube links not be show preview
             linkvimeo: /https?:\/\/(www\.)?justasamplehackeurl.com\/(\d+)($|\/)/,
             linkyoutube: /https?:\/\/(www\.)?justasmplehackurl.com\/(\d+)($|\/)/,
          },
          callbacks: {
            focus: function(e) {
              // Set a class so that we can indicate field focus
              base.$el.parent().find('p').children().each(function() {
                if ($(this).is(':empty')) $(this).remove();
              });

              base.$el.parent().addClass('active');
            },
            blur: function(e) {
              base.$el.parent().removeClass('active');
            },
            keydown: function(e) {
              // Blur event does not fire when user tabs out of the editor
              if (e.keyCode == 9) base.$el.parent().removeClass('active');
            }
          }
        });

        if (base.$el.data('char-limit')) {
          base.allowed = parseInt(base.$el.data('char-limit'));
          base.warning = 25;
          base.css = 'counter';
          base.counterElement = 'span';
          base.cssWarning = 'limit_warning';
          base.cssExceeded = 'limit_exceeded';
          base.counterText = "Characters left for field:";
          base.$counter = $('<'+ base.counterElement +' class="' + base.css + '">'+ base.counterText +'</'+ base.counterElement +'>');
          base.$elem.after(base.$counter);
          base.$elem.on('change.callback.redactor', function() {
            base.calculate(this);
          });
          base.calculate(base.$elem.data('redactor'));
        }
      };

      base.calculate = function(obj, count) {

        if (count == null) {
          count = $.fn.getCharacterCount(obj);
        }

        var available = base.allowed - count;

        if (available <= base.warning && available >= 0) {
          base.$counter.addClass(base.cssWarning);
        } else {
          base.$counter.removeClass(base.cssWarning);
        }
        if (available < 0) {
          $(obj).addClass(base.cssExceeded);
          base.$counter.addClass(base.cssExceeded);
        } else {
          $(obj).removeClass(base.cssExceeded);
          base.$counter.removeClass(base.cssExceeded);
        }
        if (!base.$counter.hasClass(base.css)) {
          base.$counter = base.$counter.next();
        }
      base.$counter.html(base.counterText + available);
    };

      base.cleanUpInput = function() {
        var $elem = base.$el,
          content = $elem.val();
        // Store original value for "raw" mode
        if (!$elem.data('original-val')) $elem.data('original-val', $elem.val());

        // Convert unsafe liquid tags to attributes on an element
        val = (content || '').replace(/\<\!-- .* \--\>?/g, "").replace(/\{\%([^<]*)\<(tr|td|th|tbody|thead)/g, function($0, $1, $2) {
          var tags = _.compact($1.split(/\%\}\s*/).map(function(t) {
            var tag = $.trim($.trim(t).replace(/^\{\%/,''));
            if (!tag.match(/^end/)) return tag;
          }));
          return '<' + $2 + ' data-liquid-tags="'+ $.toJSON(tags).replace(/"/g, '&quot;') + '" ';
        })
        .replace(/\<\/(tr|td|th|tbody|thead)\>\s*\{\%\s*end[^<]+\</g, "</$1><");

        $elem.val(val);
      };

      base.asyncInit = function () {
        base.$el.addClass("textarea-rte-loading");
        setTimeout(function() {
          base.init();
        })
      };

      // Run initializer
      base.asyncInit();
    };

    $.richText.defaultOptions = {
    };

    $.fn.richText = function(options){
      return this.each(function(){
        (new $.richText(this, options));
      });
    };

    /* Underline button added after the italic button */
      if (typeof jq22 != 'undefined') {
        jq22.Redactor.prototype.underline = function(){
          return {
            init: function(){
              var button = this.button.addAfter('italic', 'underline', 'Underline');

              this.button.addCallback(button, this.underline.format);
            },
            format: function(){
              this.inline.format('u');
              this.button.$toolbar.find('.re-underline').toggleClass('active');
            }
          };
        };

        /* Superscript and subscript buttons added after the strikethrough button */

        jq22.Redactor.prototype.supsub = function()
          {
            return {
              init: function()
              {
                var sup = this.button.addAfter('deleted', 'superscript', 'S²');
                var sub = this.button.addAfter('superscript', 'subscript', 'S₂');

                this.button.addCallback(sup, this.supsub.formatSup);
                this.button.addCallback(sub, this.supsub.formatSub);
              },
                formatSup: function()
              {
                this.inline.format('sup');
                this.button.$toolbar.find('.re-superscript').toggleClass('active');
              },
                formatSub: function()
              {
                this.inline.format('sub');
                this.button.$toolbar.find('.re-subscript').toggleClass('active');
              }
            };
          };

          /* Undo/Redo buttons */

        jq22.Redactor.prototype.undoredo = function()
          {
            return {
              init: function()
              {
                var undo = this.button.add('undo', 'Undo');
                var redo = this.button.addAfter('undo', 'redo', 'Redo');

                this.button.addCallback(undo, this.buffer.undo);
                this.button.addCallback(redo, this.buffer.redo);
              }
            };
          };

        /* Listed dropdown for custom list icons */
        /* unordered list, ordered list, outdent, indent */

        jq22.Redactor.prototype.listed = function()
        {
          return {
            langs: {
              en: {
                "listed": "Lists",
                "listed-unordered": "<i class='texteditor txt-unorderedlist'></i>",
                "listed-ordered": "<i class='texteditor txt-orderedlist'></i>",
                "listed-outdent": "<i class='texteditor txt-outdent'></i>",
                "listed-indent": "<i class='texteditor txt-indent'></i>"
              }
            },
            init: function()
            {
              var that = this;
              var dropdown = {};

              dropdown.unordered = { title: that.lang.get('listed-unordered'), func: that.listed.setUnordered };
              dropdown.ordered = { title: that.lang.get('listed-ordered'), func: that.listed.setOrdered };
              dropdown.outdent = { title: that.lang.get('listed-outdent'), func: that.listed.setOutdent };
              dropdown.indent = { title: that.lang.get('listed-indent'), func: that.listed.setIndent };
              // Replace the plugin's version of repositionItem with our version
              // They are identical except that our version will splice() the last two parents
              // (being the stencil element and the fluxx card)
              this.indent.repositionItem = that.listed.repositionItem;

              var button = this.button.addAfter('subscript','listed', this.lang.get('listed'));
              this.button.addDropdown(button, dropdown);
            },
            setUnordered: function()
            {
              //this.selection.remove();
              this.list.toggle('unorderedlist');
            },
            setOrdered: function()
            {
              //this.selection.remove();
              this.list.toggle('orderedlist');
            },
            repositionItem: function(item) {
              var elem = item.prev();
              if (0 !== elem.length && "LI" !== elem[0].tagName) {
                this.selection.save();
                var parents = item.parents("li",this.core.editor()[0]);
                // Need to remove the last two parents as one of them is the Fluxx Card and the other is the Text Area element itselg
                // If we leave these in the parents array, the list item will be added to the dashboard as a new card AND
                // it will be added to the stencil as a new form element
                parents.splice(parents.length - 2)
                parents.after(item),this.selection.restore()
              }
            },
            setOutdent: function()
            {
              // pulled this code out of the Redactor plugin and moved it here for more control
              // No changes were made to the code except for the variable names
              // See GM-34169
              if (this.list.get()) {
                var elem = $(this.selection.current());
                elem.closest("ul, ol");
                this.buffer.set(), document.execCommand("outdent");
                var item = $(this.selection.current()).closest("li", this.core.editor()[0]);
                if (this.utils.isCollapsed() && this.listed.repositionItem(item), 0 === item.length) {
                  document.execCommand("formatblock", !1, "p"), item = $(this.selection.current());
                  var object = item.next();
                  0 !== object.length && "BR" === object[0].tagName && object.remove();
                }
                this.selection.save(), this.indent.removeEmpty(), this.indent.normalize(), this.selection.restore();
              }
            },
            setIndent: function()
            {
              this.indent.increase();
            }
          };
        };

          /* Alignment dropdown button with left/center/right/justify */

        jq22.Redactor.prototype.alignment = function()
        {
          return {
            langs: {
              en: {
                "align": "Align",
                "align-left": "<i class='texteditor txt-align-left'></i>",
                "align-center": "<i class='texteditor txt-align-center'></i>",
                "align-right": "<i class='texteditor txt-align-right'></i>",
                "align-justify": "<i class='texteditor txt-align-justify'></i>"
              }
            },
            init: function()
            {
              var that = this;
              var dropdown = {};

              dropdown.left = { title: that.lang.get('align-left'), func: that.alignment.setLeft };
              dropdown.center = { title: that.lang.get('align-center'), func: that.alignment.setCenter };
              dropdown.right = { title: that.lang.get('align-right'), func: that.alignment.setRight };
              dropdown.justify = { title: that.lang.get('align-justify'), func: that.alignment.setJustify };

              var button = this.button.add('alignment', this.lang.get('align'));
              this.button.addDropdown(button, dropdown);
            },
            removeAlign: function()
            {
                  this.block.removeClass('text-center');
                  this.block.removeClass('text-right');
                  this.block.removeClass('text-justify');
            },
            setLeft: function()
            {
              this.buffer.set();
              this.alignment.removeAlign();
            },
            setCenter: function()
            {
              this.buffer.set();
              this.alignment.removeAlign();
              this.block.addClass('text-center');
            },
            setRight: function()
            {
              this.buffer.set();
              this.alignment.removeAlign();
              this.block.addClass('text-right');
            },
            setJustify: function()
            {
              this.buffer.set();
              this.alignment.removeAlign();
              this.block.addClass('text-justify');
            }
          };
        };

        // determine button to focus
        var determineButtonFocus = function(nextElement, firstElement) {
          if (nextElement != null) { 
            $(nextElement).attr("tabindex", 0); 
            $(nextElement).focus(); 
          } else { 
            $(firstElement).attr('tabindex', 0);
            $(firstElement).focus(); 
          }
        }
        // determine dropdown element to focus
        var determineDropdownFocus = function(nextElement, firstElement) {
          if (nextElement != null) {
            $(nextElement).find('a').focus();
          } else {
            $(firstElement).find('a').focus();
          }
        }
        // fetch dropdown menu
        var fetchDropdownMenu = function(attr, uuid) {
          var dropdownSelector = '.redactor-dropdown-box-' + attr + '.redactor-dropdown-' + uuid;
           return $(dropdownSelector).children();
        }
          /* Replace all of the toolbar icons with custom ones */
        /* Make sure the theming plugin is called last after the other plugins are initialized */
        jq22.Redactor.prototype.theming = function(){
          return {
            init: function (){

              this.button.setIcon(this.button.get('format'), '<i class="texteditor txt-format"></i>');
              this.button.setIcon(this.button.get('bold'), '<i class="texteditor txt-bold"></i>');
              this.button.setIcon(this.button.get('italic'), '<i class="texteditor txt-italic"></i>');
              this.button.setIcon(this.button.get('deleted'), '<i class="texteditor txt-deleted"></i>');
              this.button.setIcon(this.button.get('listed'), '<i class="texteditor txt-lists"></i>');
              this.button.setIcon(this.button.get('underline'), '<i class="texteditor txt-underline"></i>');
              this.button.setIcon(this.button.get('superscript'), '<i class="texteditor txt-superscript"></i>');
              this.button.setIcon(this.button.get('subscript'), '<i class="texteditor txt-subscript"></i>');
              this.button.setIcon(this.button.get('alignment'), '<i class="texteditor txt-align"></i>');
              this.button.setIcon(this.button.get('undo'), '<i class="texteditor txt-undo"></i>');
              this.button.setIcon(this.button.get('redo'), '<i class="texteditor txt-redo"></i>');
              var btns = this.button.getButtons();
              var uuid = this.uuid;
              btns.each(function(index){
                var relAttr = $(this).attr('rel');
                var mouseDown = new Event('mousedown');
                // set first button tabindex to 0 
                // and set every other button tabindex to -1 
                if (index == 0) { 
                  $(this).attr('tabindex', 0);
                } else { 
                  $(this).attr('tabindex', -1);
                }
                // add an event listener when arrow keys, space bar and enter key are pressed 
                $(this).on('keydown', function(e) {
                  var firstEl = btns[0]
                  var nextEl = btns[index + 1];
                  var prevEl = btns[index - 1];
                  var lastEl = btns[btns.length - 1];
                  // right arrowkey
                  if (e.keyCode == 39) { 
                    e.preventDefault(); 
                    // set current button tabindex to -1 
                    $(this).attr('tabindex', -1); 
                    // if not last button set next button tabindex to 0 
                    determineButtonFocus(nextEl, firstEl);
                    // left arrowkey
                  } else if (e.keyCode == 37) {
                    e.preventDefault(); 
                    // set current button tabindex to -1
                    $(this).attr('tabindex', -1); 
                    // if not first button set previous button tabindex to 0
                    determineButtonFocus(prevEl, lastEl);
                    // enterkey
                  }  else if (e.keyCode == 13) {
                    e.preventDefault();
                    this.dispatchEvent(mouseDown);
                    // spacebar
                  } else if (e.keyCode == 32) {
                    // do nothing for dropdown buttons
                    if ($(this).hasClass('redactor-toolbar-link-dropdown')) {
                      e.preventDefault();
                      $(this).focus();
                      return;
                    }
                    this.dispatchEvent(mouseDown);
                    // up and down arrowkeys
                  } if (e.keyCode == 38 || e.keyCode == 40) {
                    if ($(this).hasClass('redactor-toolbar-link-dropdown')) {
                      // check if dropdown exists, if it doesn't show dropdown with first or last menu item on the dropdown selected
                      // NB: dropdown menu is not available in the DOM until a click/mousedown is done
                      var dropdownMenu = fetchDropdownMenu(relAttr, uuid);
                      var firstMenuLink = dropdownMenu[0];
                      var lastMenuLink = dropdownMenu[dropdownMenu.length - 1];
                      if (!dropdownMenu || (dropdownMenu && !dropdownMenu.parent().is('visible'))) {
                        e.preventDefault();
                        this.dispatchEvent(mouseDown);
                        // set selected menu item
                        if (e.keyCode == 38) {
                          $(firstMenuLink).find('a').focus();
                        } else {
                          $(lastMenuLink).find('a').focus();
                        }
                      }
                    }
                  }
                });
                // add event listeners for dropdown buttons so user can navigate through using keyboard
                $(this).on('mousedown', function(e) {
                  if ($(this).hasClass('redactor-toolbar-link-dropdown')) {
                    e.preventDefault(); 
                    // NB: dropdown menu is not available in the DOM until a click/mousedown is done so it can only be fetched after event is triggered
                    var dropdownMenu = fetchDropdownMenu(relAttr, uuid);
                    var menuLength = dropdownMenu.length;
                    var firstMenuLink = dropdownMenu[0];
                    var lastMenuLink = dropdownMenu[menuLength - 1];
                    // focus on button instead of moving to textarea
                    $(this).focus();
                    var dropdownButton = this;
                    // add event listeners to dropdown menu
                    dropdownMenu.each(function(index) {
                      if (index == 0) {
                        $(this).find('a').focus();
                      }
                      var nextMenuLink = dropdownMenu[index + 1];
                      var prevMenuLink = dropdownMenu[index - 1];
                      $(this).on('keydown', function(e) {
                        // arrowkeys
                        if (e.keyCode == 37 || e.keyCode == 38) {
                          e.preventDefault();
                          determineDropdownFocus(prevMenuLink, lastMenuLink);
                        } else if (e.keyCode == 39 || e.keyCode == 40) {
                          e.preventDefault(); 
                          determineDropdownFocus(nextMenuLink, firstMenuLink);
                          // enter key
                        } else if (e.keyCode == 13) {
                          e.preventDefault();
                          $(this).find('a')[0].dispatchEvent(mouseDown);
                          // tab key
                        } else if (e.keyCode == 9) {
                          e.preventDefault();
                          $(dropdownButton).focus();
                        }
                      });
                    });
                  }
                })
              });
            }
          };
        };
      }

})(jQuery);
