(function($){
  $.fn.extend({
    initGranteePortal: function() {
      _.mixin(_.string.exports());
      $('html').areaDetailTransform();

      $.fn.installFluxxDecorators();
      $('.notice').delay(10000).fadeOut('slow');

      $.fn.placeholderFallback();
    },
    areaDetailTransform: function() {
      var $area = $(this);
      $area.find('.multiple-select-transfer select[multiple="true"], .multiple-select-transfer select[multiple="multiple"]').selectTransfer();
      setTimeout(function() {
        $area.find('.step:first').click();
      }, 100);

      var once = false;
      if ($('[data-reveal-if]').revealIf) $('[data-reveal-if]').revealIf();

      $('[data-trigger-field]', $area).each(function() {
        if (!once) {
          once = true;
          // Refresh dropdowns when closing a modal
          $area.fluxxCard().bind('close.fluxx.modal', function (e, $target, url) {
            if (!$target) return;
            // Capture the url returned from the create operation, this will contain the ID
            // of the newly created element.
            var $select = $target.parent().prev();

            var modelID = url.match(/\/(\d+)$/);
            if (modelID)
              modelID = modelID.pop();
            if (modelID && $select.length) {
              $select.change(function() {
                $select.unbind('change');
                $select.val(modelID)
              });
              // Refresh user dropdowns
              $('[data-related-child]', $area.fluxxCard()).each(function() {
                $input = $(this);
                _.each($input.attr('data-related-child').split(/,/), function($child) {
                  $child = $child.replace(/^\./, '');
                  if ($select.hasClass($child))
                    $input.change();
                });
              });
            }
          });
        }
        var $link = $(this);
        $($link.attr('data-trigger-field'), $area).change(function () {
          var $elem = $(this);
          if ($elem.val()) {
            // Put the organization ID into the link to create a new user
            // TODO This field name should not be hardcoded
            var org_id = parseInt($elem.val());
            if (org_id)
              $link.attr('href', $link.show().attr('href')
                .replace(/([\&\?])user%5Btemp_organization_id%5D=[0-9]*/, "$1user%5Btemp_organization_id%5D=" + org_id ));
          } else {
            // Hide "add new" links if no organization is selected
            $link.hide();
          }
        }).change();
      });
      $('input[as="date"],input[as="datetime"]').live('focus', function() {
        var $elem = $(this);
        if (!$elem.hasClass('hasDatepicker')) $elem.fluxxDatePicker();
      });
      $('.datetime input').fluxxDatePicker();
      if ($('[data-reveal-if]').revealIf) $('[data-reveal-if]').revealIf();
      $('.edit [data-char-limit]').not('.wysiwyg').each(function() {
        var $input = $(this),
          limit = parseInt($input.data('char-limit'));
        $input.charCount({
          allowed: limit
        });
      });
      if ($.fluxx && $.fluxx.util)
        $.fluxx.util.autoGrowTextArea($('textarea'));

      $('li.wysiwyg textarea, textarea.wysiwyg', $area).each(function() {
        var $elem = $(this);
        $elem.rte();
      });
      $area.find('.trigger-on-load').removeClass('trigger-on-load').trigger('fluxx-load');

      // for the loi form, we have a submit sitting outside the context of the stencil.
      // grab the disabled submit with the matching externalSubmitId and enable it!
      var externalSubmitId = $area.data('external-submit-id');
      if (externalSubmitId) $('input[data-external-submit-id="'+externalSubmitId+'"][disabled]').prop('disabled', false);
    },
    installFluxxDecorators: function() {
      $.each($.fluxx.decorators, function(key,val) {
        $(key).live.apply($(key), val);
      });
    },
    loadTable: function($table, pageIncrement) {
      $table.css('opacity', '0.2');
      var url = $table.attr('data-src');
      if (url) {
        $table.attr('data-src', $table.attr('data-src').replace(/page=(\d+)/, function(a,b){
          var page = parseInt(b) + pageIncrement;
          return 'page=' + page;
        }));
        url = $table.attr('data-src');
        $.ajax({
          url: url,
          success: function(data, status, xhr){
            $table.html(data);
            $table.css('opacity', '1');
          }
        });
      } else {
        location.reload();
      }
    },
    loadGuideStarModalData: function($elem, pageIncrement) {
      $elem.addClass('loading updating');
      if (pageIncrement != 0)
        $elem.attr('data-src', $elem.attr('data-src').replace(/\&pagenum=(\d+)$/, function(a,b){
          var pagenum = parseInt(b) + pageIncrement;
          return '&pagenum=' + pagenum;
         }));

      $.ajax({
        url: $elem.attr('data-src'),
        success: function(data, status, xhr){
          $elem.closest('.search-overlay.ui-tabs.ui-widget').scrollTop(0);
          $elem.replaceWith(data);
        }
      });
    }
  });

  $.fn.extend({
    fluxxCard: function() {
      return $(document);
    },
    fluxxCardArea: function() {
      return $(document);
    },
    openCardModal: function(options) {
      var openModal = function(data, status, xhr) {
        $('.page').fadeTo('slow','.2');
        if ((data.search("</html>") != -1)) data = $(data).find('#card-body').html()
        $('<div id="modal-content">' + data + '</div>').data('area', options.area).
        dialog({
          modal: true,
          minWidth: 700,
          minHeight: 700,
          title: options.title,
          height: $(window).height(),
          close: function(event, ui) {
            $(this).dialog( "destroy" );
            $('#modal-content').remove();
            $('.page').fadeTo('slow','1');
          }
        }).areaDetailTransform();
      }
      if (options.text) {
        openModal(options.text);
      } else {
        $.ajax({
          url: options.url,
          type: 'GET',
          success: function(data, status, xhr){
            openModal(data, status, xhr);
          }
        });
      }

      $(document).data('modal-target', options.target);
      $(document).data('on-success', $(this).data('on-success'));
    },
    fluxxDatePicker: function(options) {
      if (!options)
        options = { changeMonth: true, changeYear: true, dateFormat: $.fluxx.config.date_format };
      if (!$.fluxx.config.datepicker_unique_id)
        $.fluxx.config.datepicker_unique_id = 0;
      return this.each(function() {
        if ($(this).attr("data-year-range"))
          options.yearRange = $(this).attr("data-year-range");

        var unique = $.fluxx.config.datepicker_unique_id++;
        var $input = $(this);
        if ($input.data('datepicker'))
          return;
        $input.data('datepicker', true);
        var id = $input.attr('id');
        if (!id)
          $input.attr('id', 'input_' + unique);
        else if (id.match(/[a-zA-Z]/))
          $input.attr('id', $input.attr('id') + '_' + unique);
        $input.datepicker(options);
      });
    },
    serializeForm: function() {
      var $form = $(this);
      var data = $form.serializeArray();
      // Send blank values for select transfers so that values can be blanked
      $form.find('.include-blank').each(function() {
        if (!$(this).val())
          data.push({name: this.name, value: ""});
      });

      var found= {};
      var out = [];
      $.each(data, function(i, obj) {
        if (obj.name.match(/\[\]$/)) {
          out.push(obj);
        } else {
          if (!found[obj.name] || obj.value)
            found[obj.name] = obj.value;
        }
      });
      $.each(found,function(name, value) {
        out.push({name: name, value: value});
      });
      return out;
    },

    placeholderFallback: function() {
      if ($('.msie').length && !$('msie10').length) {
        $("[placeholder]").focus(function() {
          var input;
          input = $(this);

          if (input.val() === input.attr("placeholder")) {
            input.val("");
            return input.removeClass("placeholder");
          }
        }).blur(function() {
          var input;
          input = $(this);
          if (input.val() === "" || input.val() === input.attr("placeholder")) {
            input.addClass("placeholder");

            if (input.prop('type') === 'password') {
              var fallbackInput = document.createElement('input');
              fallbackInput = $(fallbackInput)
              fallbackInput.val(input.attr('placeholder'));
              fallbackInput.addClass('password placeholder');
              fallbackInput.insertAfter(input);

              fallbackInput.focus(function() {
                $('.placeholder.password').remove();
                input.show();
                input.focus();
              });

              input.hide();
            } else {
              input.val(input.attr("placeholder"));
            }
          }
        }).blur();

        return $("[placeholder]").parents("form").submit(function() {
          return $(this).find("[placeholder]").each(function() {
            var input;
            input = $(this);

            if (input.val() === input.prop("placeholder")) {
              return input.val("");
            }
          });
        });
      }
    },
    addOverlayData: function(key, value) {
      $.fluxx.log(this);
      var elemData = $(this).data('search-form-data') || {};
      elemData[key] = value;
      $(this).data('search-form-data', elemData);
    }
  });

  $.extend(true, {
    fluxx: {
      config: {
        dashboard: {
          enabled: true,
          defaultDashboard: {
            type: 'dashboard',
            name: 'Default',
            data: {cards: [], nextUid: 1},
            url: '#default'
          }
        }
      },
      decorators: {
          '.input-mask': [
            'fluxx-load', function() {
              $(this).fluxxInputMask();
            }
          ],
          'a.combine_pdfs': [
            'click', function(e) {
              e.preventDefault();
              var $link = $(e.target);

              $.fluxx.requestCombinedPdfs($link);
            }
          ],
          'select.no_of_records': [
            'change', function(e){
              e.preventDefault();
              var $table = $(this).parents('.container:first');
              var url = $table.attr('data-src').replace(/no_of_records=(\d+)/, 'no_of_records=' + $(this).val());
              url = url.replace(/page=(\d+)/, 'page=1');
              $table.attr('data-src', url);
              $.fn.loadTable($table, 0);
              return true;
            }
          ],
        'a.prev-page': [
          'click', function(e) {
            e.preventDefault();
            var $elem = $(this);
            if ($elem.hasClass('disabled')) return;
            if($elem.hasClass('guidestar-modal')) {
              var $body = $elem.closest('.fluxx-tab-body');
              $.fluxx.loadGuideStarModalData($body, -1);
            } else {
              var $area = $elem.parents('.container:first');
              $.fn.loadTable($area, -1);
            }
          }
        ],
        'a.next-page': [
          'click', function(e) {
            e.preventDefault();
            var $elem = $(this);
            if ($elem.hasClass('disabled')) return;
            if($elem.hasClass('guidestar-modal')) {
              var $body = $elem.closest('.fluxx-tab-body');
              $.fluxx.loadGuideStarModalData($body, 1);
            } else {
              var $area = $elem.parents('.container:first');
              $.fn.loadTable($area, 1);
            }
          }
        ],
          'a.to-upload': [
            'click', function(e) {
              e.preventDefault();
              var $elem = $(this);
              $('.page').fadeTo('slow','.2');
              $('<div class="upload-queue"></div>').dialog({
                minWidth: 700,
                minHeight: 400,
                open: function () {
                  $('.upload-queue').pluploadQueue({
                    url: $elem.attr('href') + '&' + $.fluxx.authenticityTokenParam(),
                    runtimes: 'html5, flash',
                    flash_swf_url: '/javascripts/lib/plupload.flash.swf',
                    documentTypeParam: $elem.data('document-type-param'),
                    documentTypeUrl: $elem.data('document-type-url'),
                    documentSubTypeUrl: $elem.data('document-subtype-url'),
                    multipart: false,
                    filters: [{title: "Allowed file types", extensions: $.fluxx.utility.strip_file_extensions($elem)}]
                  });
                },
                close: function(){
                  $(this).dialog( "destroy" );
                  $('.page').fadeTo('slow','1');
                  var $area = $elem.parents('.reports:first');
                  if (!$area.attr('data-src'))
                    $area = $elem.parents('.partial:first');
                  $.fn.loadTable($area, 0);
                }
              });
            }
          ],
          'a.submit-workflow, input.submit-workflow': [
            'click', function(e) {
              e.preventDefault();
              var $elem = $(this);
              var $area = $elem.parents('.reports:first');
              if ($area.length == 0)
                $area = $elem.closest('.container:first');
              if ($elem.attr('data-confirm') && !confirm($elem.attr('data-confirm')))
                return false;
              $area.css('opacity', '.2');
              $.ajax({
                url: $elem.attr('href') + '&no_redirect=1',
                type: 'PUT',
                data: {},
                success: function(data, status, xhr){
                  $area.css('opacity', '1');
                  if (!xhr.getResponseHeader('fluxx_result_failure') && $elem.attr('data-success-message'))
                    alert($elem.attr('data-success-message'));
                  else if (xhr.getResponseHeader('fluxx_result_failure')) {
                    alert($(data).find('.notice.error').text());
                  }
                  if ($area[0]) {
                    $.fn.loadTable($area, 0);
                  } else {
                    location.reload();
                  }
                },
                error: function( jqXHR, textStatus, errorThrown ) {
                  location.reload();
                }
              });
            }
          ],
          'a.delete-report': [
            'click', function(e) {
              e.preventDefault();
              var $elem = $(this);
              var $area = $elem.parents('.reports:first');
              if ($area.length == 0)
                $area = $elem.parents('.container:first');
              if (confirm('The report you recently submitted will be deleted. Are you sure?'))
              $.ajax({
                url: $elem.attr('href'),
                type: 'DELETE',
                data: {},
                success: function(data, status, xhr){
                  $.fn.loadTable($area, 0);
                }
              });
            }
          ],
          'a.as-delete': [
            'click', function(e) {
              e.preventDefault();
              var $elem = $(this),
                  $area = $elem.parents('[data-src]:first'),
                  message = $elem.data('confirm-message') || 'This record will be deleted. Are you sure?';
              if (confirm(message))
                $.ajax({
                  url: $elem.attr('href'),
                  type: 'DELETE',
                  success: function(data, status, xhr){
                    $.fn.loadTable($area, 0);
                  }
                });
            }
          ],
        'input.open-link' : [
           'click', function(e) {
             e.preventDefault();
             var $elem = $(this),
                  target = $elem.hasClass('new-window') ? '_blank' : '_self';
            window.open($elem.attr('data-href'), target);
          }
        ],
        'a.to-modal': [
          'click', function(e) {
            e.preventDefault();
            var $elem = $(this);
            if ($('#modal-content').is(':visible')) return;
            $elem.openCardModal({
              url:    $elem.attr('href'),
              header: $elem.attr('title') || $elem.text(),
              target: $elem,
              title: $elem.attr('title'),
              area: $elem.parents('.partial[data-src]:first'),
              text: $elem.data('text')
            });
          }
        ],
        '#modal-content form' : [
          'submit', function(e) {
            e.preventDefault();
            var $form = $(this),
                $area = $('#modal-content').data('area');

            if ($form.data('submitting')) return;
            $form.data('submitting', true);

            $('#modal-content').append('<div class="activity-indicator"></div>').addClass('loading');
            $form.find('[data-locked]').prop('disabled', false);

            $.ajax({
              url: $form.attr('action'),
              type: 'POST',
              data: $form.serialize(),
              success: function(data, status, xhr){
                $form.data('submitting', false);
                if (xhr.getResponseHeader('fluxx_result_success')) {
//                AML: Hardcoding organizations autocomplete code
                  if ($(document).data('on-success').match(/populateField/) && xhr.status == 201) {
                    var name = $('#modal-content form input:visible:first').val(),
                        target = $(document).data('modal-target'),
                        $field = $(target).parent().parent().find('input:visible:first');

                    var objectID = xhr.getResponseHeader('Location').match(/\/(\d+)$/);
                    if (objectID) objectID = objectID.pop();
                    $field.val(name).next().val(objectID).change();
                    var child = $field.attr('data-related-child');
                    if (child) {
                      var $child = $(child);
                      if ($child.attr('data-required')) {
                        $child.empty();
                      } else {
                        $child.html('<option></option>');
                      }
                      $('<option></option>').val(objectID).html(name + ' - headquarters').appendTo($child);
                      $child.val($child.children().first().val()).trigger('options_updated').change();
                    }
                  } else {
                    if ($(document).data('modal-target').data('trigger-field')) {
                      $('html').trigger('close.fluxx.modal', [$(document).data('modal-target'), xhr.getResponseHeader('Location')]);
                    } else {
                      $.fn.loadTable($area, 0);
                    }
                  }
                  $('.ui-icon-closethick').click();
                } else {
                  $('#modal-content').html(data).areaDetailTransform();
                }
                $(document).data('on-success', '');
              }
            });
          }
        ],
        'form' : [
          'submit', function(e) {
            var $form = $(this);
            if ($form.data('submitting')) {
              e.preventDefault();
              return;
            }
            $form.data('submitting', true);
            $form.find('[data-locked]').prop('disabled', false);
          }
        ],
        'input[data-on-change]': [
          'change keyup', function(e) {
            var $elem = $(this),
                js = $elem.data('on-change');

            var onChange = function() {
              eval(js);
            };

            if (js) {
              _.bind(onChange, $elem)();
            }
          }
        ],
        'input[data-autocomplete]': [
          'focus', function (e) {
            $.fluxx.util.itEndsWithMe(e);
            var $elem = $(this);
            if ($elem.data('autocomplete_initialized')) return;
            $elem.data('autocomplete_initialized', 1);

            var endPoint = $elem.attr('data-autocomplete');

            $elem.autocomplete({
              source: function (query, response) {
                $.getJSON(
                  endPoint,
                  query,
                  function(data, status){
                    response(data);
                  }
                );
              },
              focus: function (e, ui) {
                $elem.val(ui.item.label);
                return false;
              },
              select: function (e, ui) {
                $elem.val(ui.item.label);
                $elem.change()
                  .parent()
                  .find('input[data-sibling='+ $elem.attr('data-sibling') +']')
                  .not($elem)
                  .val(ui.item.value)
                  .change();

                return false;
              }
            }).change(function(e) {
              if ($elem.val() == '') {
                $elem
                  .parent()
                  .find('input[data-sibling='+ $elem.attr('data-sibling') +']')
                  .not($elem)
                  .val('').change();
                $elem.
                  parent().parent()
                  .find($elem.attr('data-related-child'))
                  .val('').change();
              }
            });
          }
        ],
          '[data-related-child]': [
            'change', function (e) {
              var $area = $(this).hasOwnProperty('fluxxCardArea') ? $(this).fluxxCardArea() : null;
              var updateChild = function ($child, parentId, relatedChildParam) {
                // Prevent stacking updates
                $child.data('updating', true);
                var relatedChildParam = relatedChildParam ? relatedChildParam : $child.attr('data-param');
                var query = {};
                if ($child.attr('data-require-parent-id') && !parentId)
                  return;
                if ($child.attr('data-param-list')) {
                  _.each($child.attr('data-param-list').split(','), function(field) {
                    var names = field.split('=');
                    if (names.length != 2)
                      return;
                    query[names[0]] = $(names[1], $area).val();
                  });
                } else {
                  query[relatedChildParam] = parentId;
                }
                $.getJSON($child.attr('data-src'), query, function(data, status) {
                  var oldVal = $child.val();
                  if ($child.attr('data-required')) {
                    $child.empty();
                  } else {
                    $child.html('<option></option>');
                  }
                  $.each(data, function(){
                    $('<option></option>').val(this.value).html(this.label).appendTo($child)
                  });
                  $child.val(oldVal).trigger('options_updated').change();
                });
              };

              var updateChildren = function($children, parentId, relatedChildParam) {
                $children.each(function(){
                  updateChild($(this), parentId, relatedChildParam);
                });
              }
              var $parent   = $(this),
                  $children = $($parent.attr('data-related-child'), $parent.parents('form').eq(0));
              if ($parent.attr('data-sibling')) {
                $('[data-sibling="'+ $parent.attr('data-sibling') +'"]', $parent.parent()).not($parent)
                  .one('change', function(){
                    updateChildren($children, $(this).val(), $parent.attr('data-related-child-param'));
                  });
              } else {
                updateChildren($children, $parent.val(), $parent.attr('data-related-child-param'));
              }
            }
          ],
          'input.sum': [
            'change', function(e) {
              e.preventDefault();
              var $elem = $(this);
              if ($elem.parent().hasClass('numeric') || $elem.parent().hasClass('amount')) {
                if ($elem.parent().hasClass('amount')) {
                  $.fluxx.formatCurrency($elem);
                } else {
                  $elem.toNumber();
                }
                var $card = $elem.fluxxCard();
                var total = 0;
                var calc = function() {
                  var $field = $(this);
                  var float = $field.asNumber()
                  if ($field.hasClass('sum') && !isNaN(float)) {
                    total = total + float;
                  }
                };
                $('[data-result-field="' + $elem.attr('data-result-field') + '"]', $card).not($($elem.attr('data-result-field'))).each(calc);
                if (!isNaN(total))
                  $.fluxx.setVal($($elem.attr('data-result-field'), $card), total);
                total = 0;
                $('[data-subtotal-field="' + $elem.attr('data-subtotal-field') + '"]', $card).not($($elem.attr('data-subtotal-field'))).each(calc);
                if (!isNaN(total))
                  $.fluxx.setVal($($elem.attr('data-subtotal-field'), $card), total);
              }
            }
          ],
          '.next-multistep-tab': [
            'click', function(e) {
              e.preventDefault();
              $('.step.selected').next().click();
            }
          ],
          '.prev-multistep-tab': [
            'click', function(e) {
              e.preventDefault();
              $('.step.selected').prev().click();
            }
          ],
          '.step': [
            'click', function(e) {
              e.preventDefault();
              if ($(this).hasClass('selected')) return;
              $('.step').removeClass('selected');
              var $elem = $(this).addClass('selected'),
                  $nav = $('.multistep-navigation'),
                  $content = $('.multistep-content'),
                  index = $elem.data('multistep-index'),
                  count = $('.multistep-navigation .step').length - 1;

              if (index == 0) {
                $('.prev-multistep-tab').fadeOut();
              } else {
                $('.prev-multistep-tab').fadeIn();
              }

              if (index == count) {
                $('.next-multistep-tab').fadeOut();
                $('.save').fadeIn();
              } else {
                $('.next-multistep-tab').fadeIn();
                $('.save').fadeOut();
              }


              $('div.multistep').hide().removeClass('selected');
              $('div.multistep:eq(' + $elem.index() + ')').fadeIn().addClass('selected');
              $content.css('height','');
              if ($nav.height() > $content.height()) $content.height($nav.height() + 16);

            }
          ],
          'a.to-preview': [
            'click', function(e) {
              $.fluxx.util.itEndsWithMe(e);
              var $elem = $(this),
                url = $elem.attr('href'),
                modal = '',
                w = $elem.data('preview-width') || "90%",
                p = $elem.data('preview-position') || ["10%"],
                previewType = $elem.data('preview-type'),
                showModal = function(close) {
                  var $modal = $(this);
                  if ($modal)
                    $modal.modal({
                      position: p,
                      overlayId: 'preview-overlay',
                      containerId: 'preview-container',
                      minWidth: w,
                      maxWidth: w,
                      closeHTML: "<a href='#' title='Close' class='modal-close'>x</a>",
                      onClose: close
                    });
                };
              showModal.call($('<div><iframe src="' + url + '" id="previewer"></iframe>' + ($elem.data('print-button') ? '<a href="#" id="print-preview-button">Print</a>' : '') + '</div>'));
              //AML: Allow javascript in iFrame to trigger an event that will close the modal
              $(document).bind("close.fluxx.modal", function(){
                $('#preview-container .modal-close').click();
              });
            }
          ],
          'input.spellcheck':[
            'click', function(e) {
              $.fluxx.util.itEndsWithMe(e);
              var $elem = $(this).attr('id', 'checkLink'),
                  $card = $elem.fluxxCard();

              // Check alert modal if needed
              if ($elem.parents('.jquery-alert')[0]) $card = $('.jquery-alert');

              var $elems = $card.find('li.text.input textarea:visible, li.textarea.input textarea:visible, li.string.input input, .mceEditor, .mce-edit-area');

              $elems.spellCheckInDialog({popUpStyle:'fancybox',theme:'clean'});
              $('#fancybox-overlay').width($('#card-table').outerWidth() + 20);
            }
          ],
          '.eligibility-quiz': [
            'fluxx-load', function(e) {

              var $elem = $(this),
                  $form = $elem.parents('form:first')
                  answers = $elem.data('answers'),
                  state = $elem.data('eligible-state'),
                  inEligibleState = $elem.data('ineligible-state'),
                  modelType = $elem.data('model-type'),
                  $form = $elem.parents('form:first'),
                  eligible = true,
                  submitLabel = $elem.data('submit-label');

              var submitLabel = $elem.data('submit-label');

              $elem.removeAttr('data-answers data-eligible-state data-ineligible-state');
              if (submitLabel) $form.find(':submit').not('.create').val(submitLabel);
              $form.find(':submit:visible').click(function(e) {
                e.preventDefault();

                var handleSubmit = function() {
                  $elem.find(':input:visible').each(function () {
                    if ($(this).val() != answers.shift()) eligible = false;
                  });

                  if (!eligible) state = inEligibleState;
                  $form.data('eligible', eligible);

                  $elem.after('<input name=' + modelType + '[state] value="' + state + '" type="hidden"/>');
                  $elem.submit();
                }

                if (shouldUseRecaptcha) {
                  grecaptcha.ready(function() {
                    grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(function(token) {
                      $elem.after('<input name="recaptchaToken" value="' + token + '" type="hidden"/>');
                      handleSubmit();
                    });
                  });
                } else {
                  handleSubmit();
                }
              });
            }
          ],
          '[href="/logout"]': [
            'click', function(e) {
              $.fluxx.util.itEndsHere(e);
              window.sessionStorage.removeItem('user_credentials');
              $.removeCookie('user_credentials');
              window.location = '/logout';
            }
          ],
          'a.to-search-modal': [
            'click', function(e) {
              $.fluxx.util.itEndsWithMe(e);
              var $elem = $(this),
                  $card = $elem.fluxxCard(),
                  $title = $elem.attr('title') || "Search";
              $.modal('<div class="search-overlay"><div class="horizontal-tabs" name="search"><ul><li><span><a href="'+ $elem.attr('href') +'">'+ $title + '</a></span></li></ul></div></div>', {
                minWidth: 700,
                minHeight: 400,
                closeHTML: '<span>Close</span>',
                close: true,
                closeOverlay: true,
                escClose:true,
                opacity: 50,
                onShow: function () {
                  $('.search-overlay').tabs({
                    beforeLoad: function(event, ui){
                      ui.panel.html("<div class='fluxx-tab-body loading updating'>&nbsp;</div>");
                    }
                  });
                  $('.search-overlay').data('parent-card', $card);
                },
                onClose: function(){
                  var $card = $('.search-overlay').data('parent-card'),
                  $searchData = $('.search-overlay').data('search-form-data') || {};
                  $.each($searchData, function(itemId, value){
                    $.fluxx.log("Setting " + itemId + " " + value);
                    var dataItem = $card.find('input#' + itemId + ',select#' + itemId);
                    if(dataItem){
                      dataItem = $(dataItem);
                      if( dataItem.attr('type') == "select" && Array.isArray(value)){
                        dataItem.html("<option></option>");
                        _.each(value, function(item){
                          dataItem.append($("<option/>").val(item[1]).text(item[0]).attr('selected', item[2]))
                        });
                      }
                      else {
                        dataItem.attr('value', value);
                      }
                    }
                  });
                  $.modal.close();
                }
              });
            }
          ],
          'input.exchange-lookup-value' : [
            'change keyup', function(e){
              var $elem = $(this);
              var einMatch = /(\d{2})[-]?(\d{0,7})?/;
              var searchUrl = $elem.siblings('a.exchange-lookup').attr('href');
              var matchResult = einMatch.exec($elem.attr('value'));

              // Disable search link until user enters at least 3 characters
              $elem.siblings('a.exchange-lookup').toggleClass('disabled', $elem.val().length < 3);

              // clear existing organization_name and ein params
              searchUrl = searchUrl.replace(/&organization_name=([^&"']*)&pagenum=1/, '');
              searchUrl = searchUrl.replace(/&ein=([^&"']*)/, '');

              if(matchResult && matchResult[2]) {
                var testValue = matchResult[1] + '-' + matchResult[2];
                if($elem.attr('value') != testValue){
                  $elem.attr('value', testValue);
                }
                if(testValue.length == 10){
                  // Valid EIN number was inputted
                  searchUrl += "&ein=" + testValue;
                } else{
                  // Split by url before EIN
                  searchUrl = searchUrl.split('ein')[0];
                }
              } else {
                // Searching for organization name keyword
                searchUrl += '&organization_name=' + $elem.attr('value') + '&pagenum=1';
              }
              $elem.siblings('a.exchange-lookup').attr('href', searchUrl);
            }
          ],
          'a.close-tabbed-modal':[
            'click', function (e){
              $.fluxx.util.itEndsWithMe(e);
              $('span.simplemodal-close').click();
            }
          ],
          'input.close-tabbed-modal':[
            'click', function (e){
              $.fluxx.util.itEndsWithMe(e);
              $('span.simplemodal-close').click();
            }
          ],
          'span.close-tabbed-modal':[
            'click', function (e){
              $.fluxx.util.itEndsWithMe(e);
              $('span.simplemodal-close').click();
            }
          ],
        }
      }
  });
})(jQuery);

$(document).ready(function() {
  $.fn.initGranteePortal();
});
